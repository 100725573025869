import React, { Component } from 'react';

import config from './config/config';
import ShowMessageDetails from './ShowMessageDetails';
import Tile from './Tile';
import TestValidationForm from './TestValidationForm';
import { ClipLoader } from 'react-spinners';
import qwest from 'qwest';

import './style/Message.css';


class Message extends Component {

	constructor(props) {
		super(props);


		this.state = {
			keyMessage: this.props.keyMessage,
			message: this.props.message,
			selectedTabIndex: this.props.selectedTabIndex,
			showTestElements: this.props.showTestElements,
			contentIsLoading: this.props.contentIsLoading,
			discardMessage: false,
			discardMessageClass: "",
			hasBeenValidated: false,
			selectedLocation: this.props.selectedLocation
		}

		this.discardMessage = this.discardMessage.bind(this);
		this.hasBeenValidated = this.hasBeenValidated.bind(this);
	}


	// componentWillReceiveProps(props) {
	// 	const refresh = this.props;
	// 	if (props.refresh !== refresh) {
	// 		this.setState({message: this.props.message});
	// 		this.setState({selectedTabIndex: this.props.selectedTabIndex});
	// 		this.setState({showTestElements: this.props.showTestElements});
	// 		this.setState({contentIsLoading: this.props.contentIsLoading});
	// 	}
	// }

	static getDerivedStateFromProps(props, state) {
		if (props.message !== state.message) {
			return {
				message: props.message,
			};
		}

		if (props.selectedTabIndex !== state.selectedTabIndex) {
			return {
				selectedTabIndex: props.selectedTabIndex,
			};
		}

		if (props.showTestElements !== state.showTestElements) {
			return {
				showTestElements: props.showTestElements,
			};
		}

		if (props.contentIsLoading !== state.contentIsLoading) {
			return {
				contentIsLoading: props.contentIsLoading,
			};
		}

		if (props.selectedLocation !== state.selectedLocation) {
			return {
				selectedLocation: props.selectedLocation,
			};
		}



		return null;
	}

	discardMessage(){
		this.setState({discardMessage: true});
		this.setState({discardMessageClass: "discarded"});

		let self = this;

		// default options to connect
		let qwestOptions = {
            dataType: 'json',
			responseType: 'json',
			crossDomain: true,
            headers: {
                'auth': config.tests.auth,
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin' : '*',
				"cache-control": "no-cache"
            },
			timeout: 60000
		};

		let dataToSend = {
			message: this.state.message.text,
			countryCode: this.state.selectedLocation.userCountry
		}

		// send request to update the validation date to be able to retreive the right
		let url = config.tests.url;
		let endpoint = config.tests.discardMessageEndpoint;

		qwest.post(url+endpoint, dataToSend, qwestOptions)
			.then(function (xhr, response) {
                console.log('message discarded');
            });

		setTimeout(function(){
			self.props.loadOneMessage(self.state.hasBeenValidated);
		});
	}

	hasBeenValidated(){
		this.setState({hasBeenValidated: true});
	}

	render () {
		return <div  key={this.state.keyMessage} className={ this.state.selectedTabIndex === 1 ? "message-container "+this.state.discardMessageClass : ""}>

			<label className={"messageTextLabel " + (typeof this.state.message === "string" ? "noResults" : (this.state.message.tiles.length > 0 ? "loaded" : "noResults"))}>  {typeof this.state.message === "string" ? this.state.message : this.state.message.text}</label>


			<ShowMessageDetails loading={this.state.contentIsLoading} message={this.state.message} name={this.state.message.text} src={!this.state.showTestElements? this.state.message.nlpOutput : this.state.message.nlpOutputBgrTeam} />


			<div hidden={!config.biggerpanTeam || !this.state.showTestElements}>
				{typeof this.state.message === "string" ? <div></div> : 
					<TestValidationForm message={this.state.message.text} newMessage={this.props.contentIsLoading} hasBeenValidated={this.hasBeenValidated} selectedLocation={this.state.selectedLocation} outputNlp={this.state.message.nlpOutputForTests} outputApi={this.state.message.data} outputApiContentProviders={this.state.message.providers} intentParser={typeof this.state.message === "string" ? "" : this.state.message.nlpOutput.intent_parser} />
				}
				<button onClick={this.discardMessage} className="btn-danger btn-discard-message" hidden={this.state.selectedTabIndex !== 1}><i className="fas fa-times"></i></button>
			</div>
			

			<div hidden={typeof this.state.message === "string"} id="tileGrid">
				{typeof this.state.message === "string" ? <div></div> : this.state.message.tiles.map((tile, j) => (
					<div key={j} className="tile-container">
						<Tile tileContent={tile} />
					</div>
				))}
			</div>
			<div hidden={typeof this.state.message !== "string" || this.state.contentIsLoading === true}>
				<div className="messageLoaderContainer">
					<ClipLoader
						color={'#4CAF50'}
						loading={true}
					/>
				</div>
			</div>
		</div>
	}
}

export default Message;