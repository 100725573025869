import React, { Component } from 'react';
import ReactJson from 'react-json-view';

// Loading = React.createClass({
// 	render: function () {
// 	  if(!this.props.loading) {
// 		return <span></span>;
// 	  }
// 	  return <span className='fa-spinner fa-spin'></span>
// 	}
// });

class ShowMessageDetails extends Component {

	constructor(props) {
		
		super(props);
		this.state = {
			message: this.props.message,
			loading: this.props.loading,
			iconClass: "fa-plus-circle",
			isHidden: true,
		}

		this.showJsonDetails = this.showJsonDetails.bind(this);
	}

	showJsonDetails(){

		if (!this.state.isHidden) {
			this.setState({iconClass: "fa-plus-circle"});
		} else {
			this.setState({iconClass: "fa-minus-circle"});
		}

		this.setState({isHidden: !this.state.isHidden});
	}

	render () {

		return (
			
			<label className="show-details"> 
			<span onClick={() => this.showJsonDetails()}><i className={"fas "+this.state.iconClass}></i> Show details</span>
				<div hidden={this.state.isHidden}  className="frame-on-page">
					<div className="scroll">
						<ReactJson className="jsonContainer" name={false} src={this.props.src} displayObjectSize={false} displayDataTypes={false} />
					</div>
				</div>
			</label>
			
		)
	}
}

export default ShowMessageDetails;