/**
 * Created by guillaumekermorgant on 02/05/2018.
 */
import React, { Component } from 'react';
import './style/StatusCheck.css';
import ApiConnector from './ApiConnector';
import config from './config/config';
// import Utils from './Utils';
import CookiesManager from './CookiesManager';

class StatusCheck extends Component {

    constructor(props) {
        super(props);

        let serverUpString = CookiesManager.load('serverUp');
        let serverUp = serverUpString !== "DOWN";

        this.state ={
            'serverUp': serverUp,
            'nlpServer': props.selectedServer,
        };
        this.checkHealth()
    }

    // static getDerivedStateFromProps(props, state) {
    //     // do things with nextProps.someProp and prevState.cachedSomeProp
    //     if (props.selectedServer !== state.nlpServer) {
    //         return {
    //             nlpServer: props.selectedServer ? props.selectedServer : state.nlpServer,
    //             // ... other derived state properties
    //         };
    //     }
    // }

    checkHealth() {

        let me = this;
        let prodApi = config.prodApi;
        ApiConnector.checkHealth(prodApi, this.state.nlpServer, function(up) {

            let cookie = up ? "UP" : "DOWN";
            CookiesManager.save('serverUp', cookie);
            // Utils.dLog("Check health up: " + up);
            me.setState({'serverUp': up});
            setTimeout(function() {
                me.checkHealth();
            }, 10000);
        });
    }

    render() {

        return (
            <div className={"statusCheckMainContainer " + (this.state.serverUp ? "serverUp" : "serverDown")}>
                <img className={this.state.serverUp ? "statusIndicator active" : "statusIndicator inactive"} alt=""/>
                <p className={"statusLabel " + (this.state.serverUp ? "serverUp" : "serverDown")}>{this.state.serverUp ? "Our server is up and running" : "We are currently deploying a new version, please try again in 5 minutes..."}</p>
            </div>
        );
    }
}

export default StatusCheck;