/**
 * Created by guillaumekermorgant on 14/06/2018.
 */

import _ from 'lodash'

import config from './config/config'
import Utils from './Utils'

class ApiParser {

    // Parse API response for a URL e.g. "https://en.wikipedia.org/wiki/La_La_Land_(film)""
    static parseResponseForUrl(parsed, response, error) {

        // Utils.dLog("\n**** API RESPONSE:\n" + JSON.stringify(response, null, 2) + "\n");

        if (!!error && (response === null || typeof response === "undefined")) {
            parsed.text = parsed.text + " - " + error;
            return parsed;
        }
        let tiles = response.data ? response.data : [];
        // Utils.dLog("Tiles: " + JSON.stringify(tiles, null, 2));
        parsed.tiles = this.parseTiles(tiles);
        let nlpOutput = {};
        let nlpInput = response["nerInput"];
        let customEntities = false;
        let website = "";
        if (!!nlpInput) {
            let href = nlpInput["href"];
            if (typeof href === "string") {
                if (_.includes(href, "m.yelp.")) {
                    customEntities = true;
                    website = "yelp";
                }
            }
            let pageCategory = nlpInput["pageCategory"];
            if (!pageCategory || pageCategory === "undefined" || typeof pageCategory !== "string") {
                let contextFromUrl = nlpInput["contextFromUrl"];
                if (!!contextFromUrl) {
                    pageCategory = contextFromUrl;
                }
            }
            else {
                let possibleCategories = {
                    "news_website": "News website",
                    "local_business": "Local business",
                    "ecommerce": "Ecommerce",
                    "ticketing": "Ticketing",
                    "encyclopedia": "Encyclopedia"
                };
                let actualPageCategory = possibleCategories[pageCategory] ? possibleCategories[pageCategory] : pageCategory;
                if (!!actualPageCategory) {
                    pageCategory = actualPageCategory;
                }
            };
            if (!!pageCategory && typeof pageCategory === "string" && pageCategory !== "" && pageCategory !== "undefined") {
                nlpOutput["page_category"] = pageCategory;
            }

            let pageTypes = nlpInput["pageTypes"];
            if (!!pageTypes) {
                if (pageTypes.length > 0) {
                    nlpOutput["page_types"] = pageTypes;
                }
            }
        }

        if (!customEntities) {
            let responseNlpOutput = response.nerOutput;
            // Utils.dLog("NLP Output: " + JSON.stringify(responseNlpOutput, null, 2));
            let entities = [];
            if (!!responseNlpOutput) {
                let nlpEntities = responseNlpOutput.ne;
                let dominatingSentiment = responseNlpOutput["dominating_sentiment"];
                let sentimentScore = responseNlpOutput["sentiment_score"];
                if (!!dominatingSentiment && !!sentimentScore) {
                    nlpOutput.sentiments = {
                        dominating_sentiment: dominatingSentiment,
                        sentiment_score: sentimentScore
                    };
                }
                let contentTopic = response.content_topic;
                if (!!contentTopic) {
                    let contentTopicScore = responseNlpOutput["content_topic_score"];
                    nlpOutput["content_topic"] = {
                        topic: contentTopic,
                        score: contentTopicScore
                    }
                }
                nlpEntities.forEach(function (entity) {
                    if (!entity) {
                        return;
                    }
                    let cleanEntity = {};
                    let entityName = entity.name;
                    let entityType = entity.categories;
                    let relevance = entity.relevance;
                    if (relevance > 0.28 || config.biggerpanTeam) {
                        cleanEntity = {
                            name: entityName,
                            category: entityType,
                            relevance: relevance
                        }
                    }
                    if (!!entity.refersTo && entity.refersTo !== "") {
                        cleanEntity["refers_to"] = entity.refersTo;
                    }
                    if (!!entity.alias) {
                        if (entity.alias.length > 0) {
                            cleanEntity["references"] = entity.alias;
                        }
                    }

                    entities.push(cleanEntity);
                });
                nlpOutput["entities"] = entities;
                if (!customEntities) {
                    let keywords = responseNlpOutput.keywords;
                    if (!!keywords) {
                        nlpOutput.keywords = keywords;
                    }
                }
                let lang = responseNlpOutput.lang;
                if (!!lang) {
                    nlpOutput.lang = lang;
                }
                nlpOutput.NLP_deployment_date = responseNlpOutput.nlp_deployment_date;
                nlpOutput.API_deployment_date = response.deploymentDate;
            }
        }
        else {
            switch (website) {
                case "yelp":
                    let yelpEntities = response["yelpEntities"];
                    if (!!yelpEntities) {
                        nlpOutput["place_information"] = yelpEntities;
                    }
                    break;
                default:
                    break;
            }
        }

        let nlpOutputIsEmpty = false;
        try {
            nlpOutputIsEmpty = Object.keys(parsed.nlpOutput).length < 1;
        } catch (error) {
        }

        if (nlpOutputIsEmpty) {
            if (!!error) {
                nlpOutput.error = error;
            }
            if (!!response.message) {
                nlpOutput.message = response.message;
            }
        }

        parsed.nlpOutput = nlpOutput;

        return parsed;
    }

    // Parse API response for a message e.g. "Wanna eat sushi?""
    static parseResponseForMessages(parsed, response, testingEnabled, error) {

        console.log('PARSED TEXT', parsed.text);
        if (!!error 
            && (response === null || typeof response === "undefined")
        ) {
            parsed.text = parsed.text + " - " + error;
            return parsed;
        }
        let tiles = response.data ? response.data : [];

        Utils.dLog("Tiles: " + JSON.stringify(tiles, null, 2));
        parsed.tiles = this.parseTiles(tiles);
        let nlpOutput = {};
        let nlpOutputBgrTeam = {};
        let apiData = response.data;
        let apiContentProviders = response.providers;
        let nlpOutputForTests = {};
        let responseNlpOutput = response.nerOutput;
        // Utils.dLog("NLP Output: " + JSON.stringify(responseNlpOutput, null, 2));
        if (!!responseNlpOutput) {
            let sentiments = responseNlpOutput.sentiments;
            if (!!sentiments) {
                delete sentiments.calculated_scores;
                nlpOutput.sentiments = sentiments;
                nlpOutputBgrTeam.sentiments = sentiments;
            }
            let intent = responseNlpOutput.intent;
            if (!!intent) {

                
                if (intent.hasOwnProperty('original_message') && intent.original_message && intent.text !== intent.original_message) {
                    nlpOutput.message = intent.original_message;
                    nlpOutputBgrTeam.message = intent.original_message;
                    nlpOutput.corrected_message = intent.text;
                    nlpOutputBgrTeam.corrected_message = intent.text;
                } else {
                    nlpOutput.message = intent.text;
                    nlpOutputBgrTeam.message = intent.text;
                }

                if (intent.hasOwnProperty("intents")) {
                    nlpOutput.intents = intent.intents && intent.intents.length > 0 ? intent.intents : "No intent";
                    nlpOutputBgrTeam.intents = intent.intents && intent.intents.length > 0 ? intent.intents : "No intent";
                } else if (intent.hasOwnProperty("intent")) {
                    nlpOutput.intents = intent.intent && intent.intent.length > 0 ? intent.intent : "No intent";
                    nlpOutputBgrTeam.intents = intent.intent && intent.intent.length > 0 ? intent.intent : "No intent";
                } else {
                    nlpOutput.intents = "No intent";
                    nlpOutputBgrTeam.intents = "No intent";
                }
                

                nlpOutput.entities = intent.entities;
                nlpOutputBgrTeam.entities = intent.entities;
                let negatedEntities = responseNlpOutput.originalNegatedEntities;
                if (!!negatedEntities) {
                    nlpOutput.negated_entities = negatedEntities;
                    nlpOutputBgrTeam.negated_entities = negatedEntities;
                }


                if (testingEnabled) {
                    let originalIntents = intent.originalIntents;
                    if (!!originalIntents) {
                        nlpOutputBgrTeam.intents_before_processing = originalIntents;
                    }
                    let originalEntities = responseNlpOutput.originalEntities;
                    // let originalEntities = responseNlpOutput.originalEntitiesBeforeProcessing;
                    if (!!originalEntities) {
                        nlpOutputBgrTeam.entities_before_processing = originalEntities;
                    }
                }

                if (!!intent.emotions) {
                    nlpOutput.emotions = intent.emotions;
                    nlpOutputBgrTeam.emotions = intent.emotions;
                }

                if (!!intent.intent_parser && testingEnabled) {
                    nlpOutputBgrTeam.intent_parser = intent.intent_parser;
                }

            }


            nlpOutput.NLP_deployment_date = responseNlpOutput.nlp_deployment_date;
            nlpOutputBgrTeam.NLP_deployment_date = responseNlpOutput.nlp_deployment_date;

            nlpOutput.API_deployment_date = response.deploymentDate;
            nlpOutputBgrTeam.API_deployment_date = response.deploymentDate;

            if (!!response.message) {
                nlpOutput.message = response.message;
                nlpOutputBgrTeam.message = response.message;
            }

            if (!!responseNlpOutput.originalIntents) {
                nlpOutputForTests.intents = responseNlpOutput.originalIntents;
            }

            if (!!responseNlpOutput.originalEntitiesBeforeProcessing) {
                nlpOutputForTests.entities = responseNlpOutput.originalEntitiesBeforeProcessing;
            }

            nlpOutputForTests.relative_entities = responseNlpOutput.originalRelativeEntities;
            nlpOutputForTests.negated_entities = responseNlpOutput.originalNegatedEntities;

        }

        parsed.nlpOutput = nlpOutput;
        parsed.nlpOutputBgrTeam = nlpOutputBgrTeam;
        parsed.data = apiData;
        parsed.nlpOutputForTests = nlpOutputForTests;
        parsed.providers = apiContentProviders;
        return parsed;
    };

    static parseTiles(tiles) {

        var i = tiles.length;
        while (i--) {
            let tile = tiles[i];
            if (!tile || tile === null || typeof tile === "undefined") {
                tiles.splice(i, 1);
            }
            if (tile.source === "uber" && tile.distance > 100.0) {
                tiles.splice(i, 1)
            }
            if (tile.source === "waze" && tile.distance > 1500.0) {
                tiles.splice(i, 1)
            }
            if (tile.source === "flightTickets" && tile.distance < 150.0) {
                tiles.splice(i, 1)
            }
        }

        return tiles;
    }
}


export default ApiParser