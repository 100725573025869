import React, { Component } from 'react';

import './style/MessageBackOffice.css';

class MessageBackOfficeTableRow extends Component {
	constructor(props) {
		super(props);
		this.state = {
			message: this.props.message
		};
	}


	static getDerivedStateFromProps(props, state) {
		if (props.message !== state.message) {
			return {
				message: props.message,
			};
		}

		return null;
	}



	render() {

		return (
			<tr>
				<td>{this.state.message.message}</td>
				<td>{this.state.message.goalIntents}</td>
				<td className={this.state.message.manualValidationNlp}>{this.state.message.manualValidationNlp}</td>
				<td>{this.state.message.commentNlp}</td>
				<td>{this.state.message.manualValidationApi}</td>
				<td>{this.state.message.commentApi}</td>
				<td>{this.state.message.validationDate}</td>
			</tr>
		);

	}

}

export default MessageBackOfficeTableRow;