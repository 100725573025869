/**
 * Created by guillaumekermorgant on 08/06/2018.
 */

import config from './config/config'
import passwordHash from 'password-hash'

// let cryptr = new Cryptr('W4ybrW0FcdFUeDDrVTBN');

class Utils {
    // Remove all non-string or empty string from an array
    static cleanStringsArray(actual) {
        console.log('CLEAN MESSAGE ARRAY : '+actual);
        let newArray = [];
        for (let i = 0; i < actual.length; i++) {
            if (actual[i] && typeof actual[i] === "string") {
                if (actual[i].trim() !== "") {
                    newArray.push(actual[i]);
                }
            }
        }
        return newArray;
    }

    static dLog(string) {
        if (config.biggerpanTeam) {
            console.log(string);
        }
    }

    // TODO: have safer way to manage passwords ()
    static pwdCheck(biggerpanTeam, password) {

        let hashed = biggerpanTeam ? "sha1$b0c4903a$1$920beda2ae5ada7ebd2fed273fcd368e76670e4e" : "sha1$136680e7$1$c2afde9216be901dc4bc1e8f5e4b7158db5cccc7";

        Utils.dLog("Password check: " + passwordHash.verify(password, hashed));

        return passwordHash.verify(password, hashed)
    }

    static isValidURL(str) {
        // let pattern = new RegExp('^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$','i');
        let pattern = /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(#[-a-z\d_]*)?$/i;
        if(!!!str.match(pattern)) {
          return false;
        } else {
          return true;
        }
      }

    static calculateStringWidth(string, document, fontSize, font) {

        if (!document || !fontSize || typeof string !== "string") {
            return null;
        }

        let testDiv = document.getElementById("TestDiv");
        if (!testDiv) {
            return null;
        }
        testDiv.textContent = string;
        testDiv.style.fontSize = fontSize;
        if (!!font) {
            testDiv.style.font = font
        }

        return testDiv.clientWidth;
    }


}

export default Utils