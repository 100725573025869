

import React, { Component } from 'react';

import qwest from 'qwest';
import config from './config/config';
import { ClipLoader } from 'react-spinners';

import './style/MessageBackOffice.css';
import MessageBackOfficeTableRow from './MessageBackOfficeTableRow';

class MessageBackOffice extends Component {
	constructor(props) {
		super(props);
		this.state = {
			checkUntested: false,
			checkPendingReview: false,
			checkWrong: false,
			checkGood: false,
			pagination: 2,
			serviceType: "3",
			loading: false,
			offset: 0,
			selectedLocation: this.props.selectedLocation,
			messages: [],
			messagesCount: 0
		};

		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
	}

	static getDerivedStateFromProps(props, state) {
		if (props.selectedLocation !== state.selectedLocation) {
			return {
				selectedLocation: props.selectedLocation,
			};
		}

		return null;
	}


	handleInputChange(event) {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

	
		this.setState({
			[name]: value
		});
	}

	handlePageChange(pageNumber) {
		console.log(`active page is ${pageNumber}`);
		this.setState({currentPage: pageNumber});
	}

	handleSubmit(){

		this.setState({loading: true});
		this.setState({messages: []});
		this.setState({firstId: null});
		this.setState({lastId: null});




		var self = this;

		let url = config.tests.url;
		let endpoint = config.tests.backofficeFilterEndpoint;

		// default options to connect
		let qwestOptions = {
            dataType: 'json',
			responseType: 'json',
			crossDomain: true,
            headers: {
                'auth': config.tests.auth,
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin' : '*',
				"cache-control": "no-cache"
            },
			timeout: 60000
		};

		let types = [];
		let numberOfTrue = 0;

		if (this.state.checkUntested) {
			types.push("untested");
			numberOfTrue++;
		} else {
			types.push("");
		}

		if (this.state.checkPendingReview) {
			types.push("pending-review");
			numberOfTrue++;
		} else {
			types.push("");
		}

		if (this.state.checkWrong) {
			types.push("wrong");
			numberOfTrue++;
		} else {
			types.push("");
		}

		if (this.state.checkGood) {
			types.push("good");
			numberOfTrue++;
		} else {
			types.push("");
		}

		if (this.state.serviceType === "4" && numberOfTrue > 1) {
			alert('Please choose only one type if you want both result to be the same.');

			return null;
		}

		let dataToSend = {
			types: types,
			serviceType: this.state.serviceType,
			pagination: this.state.pagination,
			offset: this.state.offset,
			countryCode: this.state.selectedLocation.userCountry,
			firstId: this.state.firstId,
			lastId: this.state.lastId
		}


		qwest.post(url+endpoint, dataToSend, qwestOptions)
            .then(function (xhr, response) {
				// response
				self.setState({messages:response.messages});
				self.setState({lastId: response.messages[response.messages.length-1]._id});
				self.setState({messagesCount: response.messages.length});
            })
            .catch(function (e, xhr, response) {
				console.log('error : ' + e);
            }).complete(function(){
				// handle loding view
				self.setState({loading: false});
			});
	}
	

	render() {

		let lineElement = this.state.messages.map((message, i) => (
			<MessageBackOfficeTableRow key={i} message={message} />
		));


		return <div>
			<div className="message-filters">
				<div className="inline">
					<div className="type-container">
						<h4>Types</h4>
						<label><input name="checkUntested"
							checked={this.state.checkUntested} 
							onChange={this.handleInputChange}
						 	type="checkbox" value="untested" /> Untested</label>

						<label><input name="checkPendingReview" 
							checked={this.state.checkPendingReview} 
							onChange={this.handleInputChange}
						 	type="checkbox" value="pending-review" /> Pending review</label>

						<label><input name="checkWrong" 
							checked={this.state.checkWrong} 
							onChange={this.handleInputChange}
						 	type="checkbox" value="wrong" /> Wrong</label>

						<label><input name="checkGood" 
							checked={this.state.checkGood} 
							onChange={this.handleInputChange}
						 	type="checkbox" value="good" /> Good</label>
					</div>

					<div>
						<h4>Service type</h4>
						<label><input name="serviceType" 
							checked={this.state.serviceType === "1"} 
							onChange={this.handleInputChange} 
							type="radio" value="1" /> NLP</label>
						<label><input name="serviceType" 
							checked={this.state.serviceType === "2"} 
							onChange={this.handleInputChange} 
							type="radio" value="2" /> API</label>
						<label><input name="serviceType" 
							checked={this.state.serviceType === "3"} 
							onChange={this.handleInputChange} 
							type="radio" value="3" /> Any</label>
						<label><input name="serviceType" 
							checked={this.state.serviceType === "4"} 
							onChange={this.handleInputChange} 
							type="radio" value="4" /> Both</label>
					</div>

					{/* <div>
						<h4>Pagination</h4>
						<select name="pagination" value={this.state.pagination} onChange={this.handleInputChange}>
							<option value="2">2</option>
							<option value="20">20</option>
							<option value="50">50</option>
							<option value="100">100</option>
						</select>
					</div> */}
					<span>
						<button className="validate-filters" onClick={this.handleSubmit}>Validate</button>
					</span>
				</div>
			</div>


			{/* <div>
				<Pagination
					activePage={this.state.currentPage}
					itemsCountPerPage={this.state.pagination}
					totalItemsCount={8}
					pageRangeDisplayed={1}
					onChange={this.handlePageChange}
				/>
			</div> */}

			<div>
				<table className="message-list">
					<tbody>
						<tr>
							<th>Messages ({this.state.messagesCount})</th>
							<th>Goal intents</th>
							<th>State validation Nlp</th>
							<th>Comment Nlp</th>
							<th>State validation Api</th>
							<th>Comment Api</th>
							<th>Validation date</th>
						</tr>
						
						{lineElement}
					</tbody>
					
				</table>
				<div className="">
					<ClipLoader
						color={'#4CAF50'}
						loading={this.state.loading}
					/>
				</div>
			</div>
		</div>
	}

}

export default MessageBackOffice;