/**
 * Created by guillaumekermorgant on 14/06/2018.
 */

import qwest from 'qwest'

import config from './config/config'
import ApiParser from './ApiParser';
import Utils from './Utils';

class ApiConnector {

    static configureConnection() {
        let userGroup = config.biggerpanTeam ? "biggerpan" : "other";
        qwest.setDefaultOptions({
            dataType: 'json',
            responseType: 'json',
            headers: {
                'x-access-token': config.api.authToken,
                'user-group': userGroup
            },
            timeout: 60000
        });
    }

    static getApiBaseUrl(prodApi) {
        if (config.localMode) {
            return config.api.localUrl;
        }
        return prodApi || config.prodApi ? config.api.prodUrl : config.api.devUrl;
    };

    // Call API for URL
    // prodApi: flag to know if prod api should be called or not
    // inputUrl: url that's gonna be analyzed
    static callApiForUrl(prodApi, inputUrl, callback) {

        if (typeof inputUrl !== "string") {
            return
        }
        if (inputUrl.indexOf("http://") <= -1 && inputUrl.indexOf("https://") <= -1) {
            inputUrl = "http://" + inputUrl;
        }

        // Utils.dLog("Load item for url: " + inputUrl);
        const data = encodeURIComponent(JSON.stringify({
            'href': inputUrl,
            'userCity': config.userInfo.userCity,
            'userCountry': config.userInfo.userCountry,
            'userState': config.userInfo.userState,
            'userCoordinates': config.userInfo.userCoordinates,
            'contentType': 'browser'
        }));
        // Utils.dLog("Data sent: " + JSON.stringify(data, null, 2));

        let url = ApiConnector.getApiBaseUrl(prodApi) + '/related/url/' + data;
        // Utils.dLog("URL: " + url);

        let parsed = {
            href: inputUrl,
            tiles: [],
            nlpOutput: {}
        };

        qwest.get(url)
            .then(function (xhr, response) {

                if (!response) {
                    callback(parsed);
                    return;
                }
                parsed = ApiParser.parseResponseForUrl(parsed, response);
                callback(parsed);
            })
            .catch(function (e, xhr, response) {
                Utils.dLog("Error: " + e + "\nAPI Response: " + JSON.stringify(response, null, 2));
                parsed = ApiParser.parseResponseForUrl(parsed, response, e);
                callback(parsed);
            });
    }

    // Call API for messages
    // prodApi: flag to know if prod api should be called or not
    // messages: array of messages
    // conversation: flag - if true, "messages" is an array of messages that belong to the same conversation, if false "messages" is just one message
    static callApiForMessages(apiServer, nlpServer, packageName, selectedLocation, testingEnabled, messages, index, conversation , callback) {

        let payload = {
            'data': {
                'lang': 'en',
                'nlpServer' : nlpServer,
                'contentType': 'messages',
                'conversationId': '0',
                'metadata-random': '',
                'userCity': selectedLocation.userCity,
                'userCountry': selectedLocation.userCountry,
                'userState': selectedLocation.userState,
                'userCoordinates': selectedLocation.userCoordinates,
                'timezone': selectedLocation.timezone,
                'timestamp': Date.now(),
                'packageName': packageName,
                'appVersion' : packageName !== "" ? "1.2.8 ( 10001 )" : ""
            }
        }

        // Yeah... we need to refactor the API because these JSONs look stupid
        if (conversation) {
            payload.data.data = [];
            for (let i = 0; i < messages.length; i++) {
                const message = messages[i];
                let type = i === 0 ? "latest" : "";
                payload.data.data.push({
                    content : message,
                    type : type
                })
            }
        } else {
            payload.data.data = [{
                'content': messages,
                'type': "latest"
            }];
        }

        let endpoint = conversation ? '/related/content/conversation' : '/related/content';

        let url;
        if (config.localMode) {
            url = config.api.localUrl + endpoint;
        } else {
            switch (apiServer) {
                case "prod":
                    url = config.api.prodUrl + endpoint;
                    break;
                case "dev":
                    url = config.api.devUrl + endpoint;
                    break;
            
                default:
                    url = config.api.prodUrl + endpoint;
                    break;
            }
        }
        


        let message = conversation ? messages[messages.length - 1] : (typeof messages === "string" ? messages : messages.text);
        let parsed = {
            text: message,
            tiles: [],
            nlpOutput: {},
            index: index
        };

        qwest.post(url, payload)
            .then(function (xhr, response) {

                //Utils.dLog("Response: " + JSON.stringify(response, null, 2));

                if (!response) {
                    callback(parsed);
                    return;
                }

                parsed = ApiParser.parseResponseForMessages(parsed, response, testingEnabled);
                // if (!!parsed) {
                //     parsed.index = index;
                // }
                callback(parsed);
            })
            .catch(function (e, xhr, response) {
                console.log("Error: " + e + "\nResponse: " + JSON.stringify(response, null, 2));
                // Utils.dLog("Error: " + e + "\nResponse: " + JSON.stringify(response, null, 2));
                parsed = ApiParser.parseResponseForMessages(parsed, response, testingEnabled, e);
                callback(parsed);
            });
    }

    static checkHealth(prodApi, nlpServer, callback) {
        let url = ApiConnector.getApiBaseUrl(nlpServer) + "/pipelineHealthCheck/"+nlpServer;
        qwest.get(url)
        .then(function (xhr, response) {
            // Utils.dLog("Health check response: " + JSON.stringify(response, null, 2));
            // console.log(JSON.stringify(response, null, 2));
            if (response.NLPStatus === 200) {
                callback(true);
            } else {
                callback(false);
            }
            
        })
        .catch(function (e, xhr, response) {
            Utils.dLog("Health check error: " + e + "\nResponse: " + JSON.stringify(response, null, 2));
            callback(false);
        });
    }
}


export default ApiConnector