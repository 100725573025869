/**
 * Created by guillaumekermorgant on 02/05/2018.
 */
import React, { Component } from 'react';

import './style/App.css';
import './style/Tile.css';

// import Utils from './Utils';

import Dotdotdot from 'react-dotdotdot';

import amazon from './style/images/amazon.png';
import amazon_fresh from './style/images/amazon-fresh.png';
import amazon_prime from './style/images/amazon_prime.png';
import fandango from './style/images/fandango.png';
import googleimages from './style/images/google_images.png';
import googlemaps from './style/images/googlemaps.png';
import hotel from './style/images/booking.png';
import priceline_hotel from './style/images/priceline_icon.png';
import linkedin from './style/images/linkedin.png';
import phone from './style/images/phone.png';
import flightTickets from './style/images/plane.png';
import tmdb from './style/images/tmdb.png';
import twitter from './style/images/twitter.png';
import twitter_verified from './style/images/twitter_verified.png';
import uber from './style/images/uber.png';
import video from './style/images/video.png';
import yelp from './style/images/yelp.png';
import sephora from './style/images/sephora.png';
import wikipedia from './style/images/wikipedia.png';
import email from './style/images/email.png';
import calendar from './style/images/calendar.png';
import eat24 from './style/images/eat24.png';
import restaurant from './style/images/restaurant.png';
import coffee_shop from './style/images/coffee_shop.png';
import bakery from './style/images/bakery.png';
import ice_cream_shop from './style/images/ice_cream_shop.png';
import candy_shop from './style/images/candy_shop.png';
import bar from './style/images/bar.png';
import theater from './style/images/theater.png';
import shopping from './style/images/shopping.png';
import pinIndicatorSmall from './style/images/pinIndicatorSmall.png';
import clock from './style/images/clock.png';
import share_contact from './style/images/contacts.png';
import waze from './style/images/waze.png';
import viator from './style/images/viator.png';
import boots from './style/images/boots.png';
import justeat from './style/images/justeat.png';
import ticketmaster from './style/images/ticketmaster.png';
import odeon from './style/images/odeon.png';
import tesco from './style/images/tesco.png';
import lydia from './style/images/lydia.png';
import netaporter from './style/images/net_a_porter.png';
import mrporter from './style/images/mr_porter.png';
import drizly from './style/images/drizly.jpg';
import walmartgroceries from './style/images/walmart_groceries.png';
import grubhub from './style/images/grubhub.png';
import nerdwallet from './style/images/nerdwallet.png';
import chase from './style/images/chase.png';
import lendingclub from './style/images/lendingclub.png';
import zillow from './style/images/zillow.png';
import zoopla from './style/images/zoopla.png';
import moneysupermarket from './style/images/moneysupermarket.png';
import hsbc from './style/images/hsbc.png';
import rentalcars from './style/images/rentalcars.png';
import priceline_rentalcars from './style/images/priceline_icon.png';

// Used for top left corner image indicating source of tiles (yelp, uber, facebook etc...)
const images = {
    amazon: amazon,
    amazonGeneric: amazon,
    amazonFresh: amazon_fresh,
    walmartGroceries: walmartgroceries,
    walmartGroceriesGeneric: walmartgroceries,
    walmartBeverages: walmartgroceries,
    amazon_prime: amazon_prime,
    fandango: fandango,
    googleimages: googleimages,
    googlemaps: googlemaps,
    hotel: hotel,
    priceline_hotel: priceline_hotel,
    linkedin: linkedin,
    // opentable: opentable,
    flightTickets: flightTickets,
    tmdb: tmdb,
    twitter: twitter,
    twitter_verified: twitter_verified,
    uber: uber,
    video: video,
    yelp: yelp,
    sephora: sephora,
    wikipedia: wikipedia,
    phone: phone,
    email: email,
    calendar: calendar,
    eat24: eat24,
    grubhub: grubhub,
    restaurant: restaurant,
    coffee_shop: coffee_shop,
    bakery: bakery,
    ice_cream_shop: ice_cream_shop,
    candy_shop: candy_shop,
    bar: bar,
    theater: theater,
    shopping: shopping,
    waze: waze,
    viator: viator,
    boots: boots,
    justeat: justeat,
    ticketmaster: ticketmaster,
    odeon: odeon,
    tesco: tesco,
    tescoGroceries: tesco,
    tescoBeverages: tesco,
    lydia: lydia,
    net_a_porter: netaporter,
    mr_porter: mrporter,
    drizly: drizly,
    nerdwallet: nerdwallet,
    chase: chase,
    lendingclub: lendingclub,
    zillow: zillow,
    zoopla: zoopla,
    moneysupermarket: moneysupermarket,
    hsbc: hsbc,
    rentalcars: rentalcars,
    priceline_rentalcars: priceline_rentalcars
};

// Used for top right corner image view indicating type of venues for Google Maps tiles (restaurant, bar, coffee shop etc...)
const locationTypeToImage = {
    "bar": bar,
    "bar_item": bar,
    "coffee_shop": coffee_shop,
    "coffee_shop_franchise": coffee_shop,
    "restaurant": restaurant,
    "restaurant_franchise": restaurant,
    "restaurant_item": restaurant,
    "food_item": restaurant,
    "cuisine": restaurant,
    "store": shopping,
    "store_franchise": shopping,
    "supermarket": shopping,
    "bakery": bakery,
    "bakery_item": bakery,
    "ice_cream_shop": ice_cream_shop,
    "ice_cream_item": ice_cream_shop,
    "candy_shop": candy_shop,
    "candy_shop_item": candy_shop,
    "theater": theater,
    "movie_theater": theater,
    "movie": theater,
    "theater_location": theater,
    "movie_event": theater
}

const sourceToName = {
    amazon: "Amazon",
    amazonGeneric: "Amazon",
    amazonFresh: "Fresh",
    walmartGroceries: "Walmart",
    walmartGroceriesGeneric: "Walmart",
    walmartBeverages: "Walmart",
    fandango: "Fandango",
    googleimages: "Google Images",
    googlemaps: "Google Maps",
    hotel: "Booking",
    priceline_hotel: "Priceline Hotels",
    linkedin: "LinkedIn",
    opentable: "Opentable",
    flightTickets: "Flight Tickets",
    tmdb: "TMDb",
    twitter: "Twitter",
    uber: "Uber",
    video: "Youtube",
    news: "News",
    eat24: "Eat24",
    grubhub: "Grubhub",
    yelp: "Yelp",
    sephora: "Sephora",
    wikipedia: "Wikipedia",
    phone: "Phone",
    email: "Email",
    url: "Open",
    calendar: "Calendar",
    venmo: "Venmo",
    lydia: "Lydia",
    waze: "Waze",
    viator: "Viator",
    app: "Open",
    stubhub: "StubHub",
    boots: "Boots",
    odeon: "Odeon",
    justeat: "Just Eat",
    ticketmaster: "Ticketmaster",
    tesco: "Tesco",
    tescoGroceries: "Tesco Groceries",
    tescoBeverages: "Tesco Beverages",
    net_a_porter: "Net-a-Porter",
    mr_porter: "Mr Porter",
    drizly: "Drizly",
    just_watch: "Stream",
    meetup: "Meetup",
    zillow: "Zillow",
    zoopla: "Zoopla",
    nerdwallet: "Nerdwallet",
    chase: "Chase",
    lendingclub: "Lendingclub",
    hsbc: "HSBC",
    moneysupermarket: "Moneysupermarket",
    googleplay: "Google Play",
    gameStore: "Game Store",
    webStore: "Web Store",
    missing_location: "Missing location",
    rentalcars: "Rentalcars",
    priceline_rentalcars: "Priceline Cars",
    indeed: "Indeed"
};


function isMobile(){
	if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) )
		return true;
	else return false;
}

class Tile extends Component {

    render() {

        // Manage different cases here
        if (!this.props.tileContent || this.props.tileContent === null) {
            return (
                <div className="tileContentContainer">
                </div>
            );
        }

        let image = this.props.tileContent.img;
        let source = this.props.tileContent.source;
        let title = this.props.tileContent.title;
        if (source === "walmartGroceries" && title === "Browse Walmart Groceries") {
            source = "walmartGroceriesGeneric";
        }
        let topContainerClass = "tileTopContainer " + source;
        let cornerImageSrc = images[source];
        let optionalCornerImageSrc = null;
        
        let description = this.props.tileContent.desc;
        let href = this.props.tileContent.href;
        

        // let currency = this.props.tileContent.currency;
        let fourthLine = this.props.tileContent.activity ? this.props.tileContent.activity : null;
        if (!fourthLine) {
            fourthLine = this.props.tileContent.event ? this.props.tileContent.event : null;
        }
        let calendarHostOptionalClass = fourthLine ? "one-line" : "two-lines";

        let locationType = this.props.tileContent.locationType;
        let entityType = this.props.tileContent.entityType;
        let entityName = this.props.tileContent.entityName;

        let bottomContainerClass = "tileBottomContainer " + source;
        let tileTitleClass = "tileTitle " + source;
        let tileDescriptionClass = "tileDescription " + source;
        let tileHostClass = "tileHost " + source;
        let mainImageSize = this.props.tileContent.backgroundSize ? this.props.tileContent.backgroundSize : "cover";

        if (!cornerImageSrc || typeof cornerImageSrc !== "string" || cornerImageSrc === "") {
            cornerImageSrc = "https://transparenttextures.com/patterns/asfalt-light.png"; // This is a transparent
        }
        let sourceName = this.props.tileContent.providerName || sourceToName[source];
        let captionText = sourceName;
        let captionTextCents = "";
        let longPrice = "";
        let fromPrice = null;
        let alignFromPrice = {};
        let distanceToDisplay = null;


        if (typeof sourceName !== "string") {
            captionText = "";
        }
        else {
            captionText = captionText.toUpperCase();
        }

        let numberOfLinesInTitle = 1;
        let numberOfLinesInDesc = 2;
        let numberOfLinesInHost = 1;


        // Temporary solution we implemented before implementing all tiles designs
        if (source === "wikipedia") {
            image = "https://upload.wikimedia.org/wikipedia/commons/thumb/d/de/Wikipedia_Logo_1.0.png/220px-Wikipedia_Logo_1.0.png";
            numberOfLinesInTitle = 5;
            numberOfLinesInDesc = 0;
            description = "";
            title = this.props.tileContent.desc;
        }
        if (source === "twitter") {
            description = "@" + description;
        }
        if (source === "flightTickets" && this.props.tileContent.price) {
            fourthLine = "from "+this.props.tileContent.price;
        }
        if (source === "venmo") {
            numberOfLinesInTitle = 2;
            if (this.props.tileContent.price == null) {
                captionText = "";
            }else{
                if (this.props.tileContent.convertedPrice == null) {
                    captionText = "$" + this.props.tileContent.price;

                    // creation of the browser link with the price
                    if (this.props.tileContent.action === "send") {
                        href = "https://venmo.com/paycharge?txn=pay&amount="+this.props.tileContent.price;
                    } else if (this.props.tileContent.action === "request") {
                        href = "https://venmo.com/paycharge?txn=charge&amount="+this.props.tileContent.price;
                    }
                }else{
                    captionText = "$" + this.props.tileContent.convertedPrice;

                    // creation of the browser link with the convertedPrice
                    if (this.props.tileContent.action === "send") {
                        href = "https://venmo.com/paycharge?txn=pay&amount="+this.props.tileContent.convertedPrice;
                    } else if (this.props.tileContent.action === "request") {
                        href = "https://venmo.com/paycharge?txn=charge&amount="+this.props.tileContent.convertedPrice;
                    }
                }
            }            
        }

        if (source === "lydia") {
            numberOfLinesInTitle = 2;
            if (this.props.tileContent.price == null) {
                captionText = "";
            }else{
                if (this.props.tileContent.convertedPrice == null) {
                    captionText = "£" + this.props.tileContent.price;
                }else{
                    captionText = "£" + this.props.tileContent.convertedPrice;
                }
            }
        }

        if (source === "fandango" || source === "odeon" || source === "video" || source === "viator" || source === "zillow" || source === "nerdwallet" || source === "chase" || source === "lendingclub" || source === "hsbc" || source === "moneysupermarket" || source === "amazonGeneric" || source === "zoopla" || source === "googleplay" || source === "gameStore") {
            numberOfLinesInTitle = 3;
            numberOfLinesInDesc = 0;
        }
        if (source === "yelp" || source === "grubhub" || source === "justeat" || source === "googlemaps" || source === "uber" || source === "indeed") {
            numberOfLinesInTitle = 2;
            numberOfLinesInDesc = 0;
        }

        if (source === "waze") {
            numberOfLinesInTitle = 2;
            numberOfLinesInDesc = 0;
            if (this.props.tileContent.distanceToDisplay) {
                distanceToDisplay = this.props.tileContent.distanceToDisplay;
            }
        }


        if (source === "uber") {
            if (!!this.props.tileContent.price) {
                captionText = "UberX - " + this.props.tileContent.price;
            }

            if (isMobile() && this.props.tileContent.intentLink) {
                href = this.props.tileContent.intentLink;
            } else if (this.props.tileContent.playstoreLink) {
                href = this.props.tileContent.playstoreLink;
            }
        }
        if (source === "sephora" || source === "boots" || source === "amazon" || source === "amazonFresh" || source === "tesco" || source === "net_a_porter" || source === "mr_porter" || source === "drizly" || source === "walmartGroceries") {
            numberOfLinesInTitle = 3;
            if (!!this.props.tileContent.price) {

                console.log("PRICE " + source + " : " +this.props.tileContent.price);
                if (this.props.tileContent.price.includes('.')) {
                    let arrayPrice = this.props.tileContent.price.split('.');
                    captionText = arrayPrice[0];

                    // display cents only if it is a number smaller that the 10,000
                    if (captionText.length < 6) {
                        captionTextCents = arrayPrice[1];
                    }
                    
                }else{
                    captionText = this.props.tileContent.price;
                }
                
                //tileHostClass = tileHostClass + "";
            } else {
                if (this.props.tileContent.name === "Amazon Fresh Generic") {
                    sourceName = "Amazon Fresh";
                }
                captionText = "";
            }

            let priceText = captionText+captionTextCents;
            if (priceText.length >= 6) {
                longPrice = "longPrice";
            }

            if (source === "sephora" && this.props.tileContent.price !== this.props.tileContent.priceRange) {
                fromPrice = "from";
                let position = "9px";
                switch (priceText.length) {
                    case 1:
                        position = "5px";
                        break;
                    case 2:
                        
                        break;
                    case 3:
                        position = "10px";
                        break;
                    case 4:
                        position = "12px";
                        break;
                
                    default:
                        break;
                }
                alignFromPrice.right = position;
               
            }
        }
        if (source === "email") {
            description = this.props.tileContent.description;
        }
        if (source === "walmartGroceriesGeneric" || source === "walmartBeverages" || source === "tescoBeverages") {
            numberOfLinesInTitle = 2;
        }
        if (source === "calendar") {
            numberOfLinesInTitle = 2;
            numberOfLinesInDesc = 3;
            numberOfLinesInHost = 1;
            if (calendarHostOptionalClass === "two-lines") {
                numberOfLinesInHost = 2;
            }
            if (!!this.props.tileContent.dateDescription) {
                description = this.props.tileContent.dateDescription;
                description = description.replace(/ pm/g, " pm").replace(/ am/g, " am");
            }
            captionText = "";
            if (!!this.props.tileContent.location) {
                captionText = this.props.tileContent.location;
            }else{
                numberOfLinesInDesc = 4;
            }
        }
        if (source === "url" || source === "webStore") {
            numberOfLinesInTitle = 2;
            numberOfLinesInDesc = 0;
        }
        if (source === "hotel" || source === "priceline_hotel" || source === "stubhub" || source === "ticketmaster" || source === "meetup" || source === "opentable" || source === "rentalcars" || source === "priceline_rentalcars") {
            numberOfLinesInTitle = 3;
        }
        if (source === "googlemaps") {
            try {
                // We try to find an option corner image (knife and fork for restaurants, cup of coffee for coffee shops etc...)
                if (!!locationType && typeof locationType === "string") {
                    optionalCornerImageSrc = locationTypeToImage[locationType.toLowerCase()];
                }
                if (!optionalCornerImageSrc && !!entityType) {
                    for (let i = 0; i < entityType.length; i++) {
                        let currType = entityType[i];
                        if (typeof currType === "string") {
                            let image = locationTypeToImage[currType.toLowerCase()]
                            if (!!image && typeof image === "string") {
                                optionalCornerImageSrc = image;
                                break;
                            }
                        }
                    }
                }
                if (!optionalCornerImageSrc && !!entityName && typeof entityName === "string") {
                    let image = locationTypeToImage[entityName.toLowerCase()];
                    if (!!image && typeof image === "string") {
                        optionalCornerImageSrc = image
                    }
                }

            } catch (error) { }
        }
        
        if (source === "share_contact") {
            image = share_contact;
            let article = (typeof this.props.tileContent.article !== "undefined") ? this.props.tileContent.article + " " : '';
            console.log("Typeof article", typeof this.props.tileContent.article);
            console.log("Article", this.props.tileContent.article);
            console.log("Computed article", article);
            title = "Find " + article + this.props.tileContent.contactName + " in my contacts";
            numberOfLinesInTitle = 3;
        }

        if (source === "just_watch") {
            numberOfLinesInTitle = 3;
            if (!this.props.tileContent.img) {
                image = require("./style/images/jwImages/"+this.props.tileContent.providerSlug+".jpeg");
                mainImageSize = "contain";
            } else {
                cornerImageSrc = require("./style/images/jwImages/"+this.props.tileContent.providerSlug+".jpeg");
            }
            
            captionText = "";
        }


        if (source === "tmdb") {
            if (this.props.tileContent.subType === "person") {
                numberOfLinesInTitle = 3;
            }
        }

        if (title && title.length > 16) {
            if (title.includes("/")) {
                title = title.replace(/\//g, "/ ");
            }
            // if (title.includes('.')) {
            //     title = title.replace(/\./g, " .");
            // }
        }

        //Utils.dLog("Source: " + source);
        //Utils.dLog("Caption text: " + captionText);

        let tile = <div></div>;

        let calendarEventStyle = {
            fontSize: '10px',
            fontWeight: '800',
            //maxHeight: '16px',
            color: 'black',
            // overflow: 'hidden',
            // textOverflow: 'ellipsis',
            // display: '-webkit-box',
            // WebkitLineClamp: this.props.numberOfLines,
            // WebkitBoxOrient: 'vertical',
            marginTop: '1px',
            marginBottom: '0px',
            marginLeft: '4px',
            lineHeight: '1'
        }

        var defaultURLClass = '';
        // Check if we have to display the corner image for the magic card
        if (this.props.tileContent.hasOwnProperty("isDefaultImage") && this.props.tileContent.isDefaultImage) {
            cornerImageSrc = "https://transparenttextures.com/patterns/asfalt-light.png";
            if (this.props.tileContent.source === 'url') {
                defaultURLClass = ' default';
            }
        }

        // In some cases, we need to be a specific tile
        switch (source) {
            case "flightTickets":
                let subtitle = "PRICELINE";
                description = this.props.tileContent.desc2;
                tile = <a href={href} target="_blank">
                    <img id="cornerImageId" className="corner-image" src={cornerImageSrc} alt="" />
                    <p hidden={!fourthLine} className={"flight-tickets-price"}>{fourthLine}</p>
                    <div className={topContainerClass}>
                        <Dotdotdot clamp={1}>
                            <p className={tileTitleClass}>{title}</p>
                        </Dotdotdot>
                        <Dotdotdot clamp={2}>
                            <p className={tileDescriptionClass}>{description}</p>
                        </Dotdotdot>
                    </div>
                    <div className={bottomContainerClass}>
                        <Dotdotdot className="tile-host-container" clamp={1}>
                            <p className={tileHostClass}>{subtitle}</p>
                        </Dotdotdot>
                    </div>
                </a>;
                break;
            case "twitter":
                tile = <a href={href} target="_blank">
                    <img id="cornerImageId" className="corner-image" src={cornerImageSrc} alt="" />
                    <div className={topContainerClass}>
                        <img className={"tileImage " + source} src={image} alt="" />
                    </div>
                    <div className={bottomContainerClass}>
                        <Dotdotdot clamp={numberOfLinesInTitle}>
                            <p className={tileTitleClass  + " line-clamp-" + numberOfLinesInTitle}>{title}</p>
                        </Dotdotdot>
                        <Dotdotdot clamp={numberOfLinesInDesc}>
                            <p className={tileDescriptionClass}>{description}</p>
                        </Dotdotdot>
                        <Dotdotdot className="tile-host-container" clamp={numberOfLinesInHost}>
                            <p className={tileHostClass}>{captionText}</p>
                        </Dotdotdot>
                    </div>
                </a>;
                break;
            case "amazon":
            case "amazonFresh":
            case "sephora":
            case "boots":
            case "tesco":
            case "net_a_porter":
            case "mr_porter":
            case "drizly":
            case "walmartGroceries":
            case "just_watch":
                tile = <a href={href} target="_blank">
                    <img id="cornerImageId" className="corner-image" src={cornerImageSrc} alt="" />
                    <div className={topContainerClass}>
                        <img className={"tileImage " + source + " " +mainImageSize} src={image} alt="" />
                    </div>
                    <div className={bottomContainerClass}>
                        <Dotdotdot clamp={numberOfLinesInTitle}>
                            <p className={tileTitleClass  + " line-clamp-" + numberOfLinesInTitle}>{title}</p>
                        </Dotdotdot>
                        <Dotdotdot clamp={numberOfLinesInDesc}>
                            <p className={tileDescriptionClass}>{description}</p>
                        </Dotdotdot>
                        <Dotdotdot className="tile-host-container" clamp={numberOfLinesInHost}>
                            <p className={tileHostClass + " " + longPrice}> <span>{sourceName}</span> <span style={alignFromPrice} hidden={!fromPrice} className="from-price">{fromPrice}</span>  <span className="price">{captionText} <span hidden={!captionTextCents} className="cents">{captionTextCents}</span></span> </p>
                        </Dotdotdot>
                    </div>
                </a>;
                break;
            case "venmo":
            case "lydia":
                    tile = <a href={href} target="_blank">
                        <img id="cornerImageId" className="corner-image" src={cornerImageSrc} alt="" />
                        <div className={topContainerClass}>
                            <img className={"tileImage " + source} src={image} alt="" />
                        </div>
                        <div className={bottomContainerClass}>
                            <Dotdotdot clamp={numberOfLinesInTitle}>
                                <p className={tileTitleClass  + " line-clamp-" + numberOfLinesInTitle}>{title}</p>
                            </Dotdotdot>
                            <Dotdotdot clamp={numberOfLinesInDesc}>
                                <p className={tileDescriptionClass}>{description}</p>
                            </Dotdotdot>
                            <Dotdotdot className="tile-host-container" clamp={numberOfLinesInHost}>
                                <p className={tileHostClass}>{source} <span className="price">{captionText}</span></p>
                            </Dotdotdot>
                        </div>
                    </a>;
                    break;
            case "googlemaps":
            case "waze":
                tile = <a href={href} target="_blank">
                    <img id="cornerImageId" className={"corner-image " + source} src={cornerImageSrc} alt="" />
                    <img hidden={!optionalCornerImageSrc} id="optionalCornerImageId" className={"optional-corner-image"} src={optionalCornerImageSrc} alt="" />
                    <div className={topContainerClass}>
                        <img className={"tileImage " + source} src={image} alt="" />
                    </div>
                    <div className={bottomContainerClass}>
                        <Dotdotdot clamp={numberOfLinesInTitle}>
                            <p className={tileTitleClass + " line-clamp-" + numberOfLinesInTitle} >{title}</p>
                        </Dotdotdot>
                        <Dotdotdot className="tile-host-container" clamp={numberOfLinesInHost}>
                            <p className={tileHostClass}>{captionText} <span className="distance">{distanceToDisplay}</span></p>
                        </Dotdotdot>
                    </div>
                </a>;
                break;
            case "calendar":
                tile = <a href={href} target="_blank">
                    <img id="cornerImageId" className={"corner-image " + source} src={cornerImageSrc} alt="" />
                    <img hidden={!optionalCornerImageSrc} id="optionalCornerImageId" className={"optional-corner-image"} src={optionalCornerImageSrc} alt="" />
                    <div className={topContainerClass}>
                        <img className={"tileImage " + source} src={image} alt="" />
                        <p className="calendar-title">{title}</p>
                    </div>
                    <div className={bottomContainerClass}>
            
                        <p hidden={!fourthLine || fourthLine === ""} style={calendarEventStyle}>{fourthLine}</p>
                        <Dotdotdot clamp={numberOfLinesInDesc}>
                            <p hidden={!description || description === ""} className={tileDescriptionClass}><img src={clock} alt="clock"></img> {description}</p>
                        </Dotdotdot>
                        {/* <IconedLabel hidden={!description || description === ""} label={description} imgSrc={clock} numberOfLines="2" marginTop="2px"/> */}
                        {/* <IconedLabel hidden={!captionText || captionText === ""} label={captionText} imgSrc={pinIndicatorSmall} numberOfLines="2" marginTop="0px"/> */}
                        <Dotdotdot clamp={numberOfLinesInHost}>
                            <p hidden={!captionText || captionText === ""} className={tileDescriptionClass}><img src={pinIndicatorSmall} alt="pin"></img> {captionText}</p>
                        </Dotdotdot>
                    </div>
                </a>;
                break;
            default:
                tile = <a href={href} target="_blank">
                    <img id="cornerImageId" className={"corner-image " + source} src={cornerImageSrc} alt="" />
                    <div className={topContainerClass}>
                        <img className={"tileImage " + ((source === "url" && this.props.tileContent.backgroundSize === "cover")? "" : source) + defaultURLClass + " " + mainImageSize} src={image} alt="" />
                    </div>
                    <div className={bottomContainerClass}>
                        <Dotdotdot clamp={numberOfLinesInTitle}>
                            <p className={tileTitleClass + " line-clamp-" + numberOfLinesInTitle}>{title}</p>
                        </Dotdotdot>
                        <Dotdotdot clamp={numberOfLinesInDesc}>
                            <p className={tileDescriptionClass}>{description}</p>
                        </Dotdotdot>
                        <Dotdotdot className="tile-host-container" clamp={numberOfLinesInHost}>
                            <p className={tileHostClass}>{captionText}</p>
                        </Dotdotdot>
                    </div>
                </a>;
                break;
        }

        return (
            <div className="tileContentContainer">
                {tile}
            </div>
        );
    }
}

export default Tile;