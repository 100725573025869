import React, { Component } from 'react';


class Loading extends Component {

	// constructor(props) {
	// 	super(props);
	// }

	render () {
		if(!this.props.loading) {
			if (this.props.iconToDisplay) {
				return  <i className={"fas "+this.props.iconToDisplay}></i>;
			} else {
				return <span></span>;
			}
			
		}
		return  <i className='fas fa-spinner fa-spin'></i>
	}
}

export default Loading;