// Created by Guillaume Kermorgant for Biggerpan, Inc. on 02/07/2018

import ReactCookie from 'react-cookie';
import config from './config/config';

class CookiesManager {
    // Remove all non-string or empty string from an array
    static save(key, value) {
        ReactCookie.save(key, value, {
            path: '/',
            maxAge: config.cookiesMaxAge
        });
    }

    static load(key) {
        return ReactCookie.load(key);
    }
}

export default CookiesManager