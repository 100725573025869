import React, { Component } from 'react';
import Popup from "reactjs-popup";
import qwest from 'qwest';
import ReactJson from 'react-json-view';
import Textarea from "react-textarea-autosize";
import { ClipLoader } from 'react-spinners';
import CookiesManager from './CookiesManager';
import Utils from './Utils';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';


import './style/App.css';
import './style/Tile.css';
import './style/TestValidationForm.css';
import './style/react-tabs.css';
import './style/toggle-switch.css';
import './style/LocationIndicator.css';

import config from './config/config';
import ApiConnector from './ApiConnector'
import Tile from './Tile';
import StatusCheck from './StatusCheck';
import Message from './Message';
import MessageBackOffice from './MessageBackOffice';

import biggerpanLogo from './style/images/biggerpan_logo.png';
import pinIndicator from './style/images/pinIndicator.png';
import magicchatlogo from './style/images/magicchat.png';
import smartmessageslogo from './style/images/smartmessages.png';

class App extends Component {

    myContainer = null;

    constructor(props) {
        super(props);

        let authenticated = this.checkAuthentication();

        let messages = CookiesManager.load('input-messages');
        let showTestElements = CookiesManager.load('setting-show-test-element');

        // cookies doesn't store boolean so we change it on load
        if (showTestElements === "false") {
            showTestElements = false;
        }else if (showTestElements === "true") {
            showTestElements = true;
        }

        let typeTestAuto = "untested";

        let messagesArray = [];
        let messagesOutput = [];
        let messagesTestInfo = [];
        let defaultNlpServer = "prod";
        let defaultApiServer = "prod";
        let defaultPackageName = "";
        if (!!messages && typeof messages === "string") {
            if (messages === "undefined") {
                messages = "";
            }
            //messagesArray = CookiesManager.load('messages-array');
            messagesOutput = CookiesManager.load('messages-output');
            if (!!messagesOutput) {
                if (messagesOutput.length === 1) {
                    let messageOutput = messagesOutput[0];
                    let messageText = messageOutput.text;
                    if (messageText !== messages.trim()) {
                        messagesOutput = [];
                        messagesArray = [];
                    }
                }
            }
            else {
                messagesArray = [];
                messagesOutput = [];
            }
        }

        // We experience problems with cached tiles so for now we juste don't show them
        // TODO: fix this ;)
        messagesOutput = [];

        let inputUrl = CookiesManager.load('inputUrl');
        if (typeof inputUrl !== "string") {
            inputUrl = config.defaultURL;
        }

        if ((messages === "" || typeof messages !== "string") && messagesOutput.length === 0) {
            messages = config.defaultMessages.join("\n");
        }



        // TODO: fix that
        // let urlAPIOutput = CookiesManager.load('url-api-output');
        // Utils.dLog("*** Cookie urlAPIOutput: " + urlAPIOutput);
        // if (!urlAPIOutput) {
        //     urlAPIOutput = [];
        // }
        let urlAPIOutput = [];
        if (config.debug) {
            urlAPIOutput = config.mockedNLPResponse
            messagesOutput = config.mockedMessagesOutput
        }

        let userFullAddress = config.userInfo.userCoordinates.fullAddress;
        let userTimezone = config.userInfo.timezone;

        // change location of the test
        const jsonLocation = config.userLocations;
        var keyValueLocations = [];
        var arrayLocations = [];
        Object.keys(jsonLocation).forEach(function(key) {
            keyValueLocations[key] = jsonLocation[key];
            arrayLocations.push(jsonLocation[key]);
        });
        let defaultSelectedLocation = keyValueLocations[config.userInfo.userCity];

        const listLocationsRadios = arrayLocations.map((location, index) =>
            <p key={index}><input name="radio-location" type="radio" value={location.userCity ? location.userCity : "Missing Location"} onChange={this.handleLocationChange} />{location.userCity ? location.userCity : "Missing Location"}</p>
        );

        this.state = {
            messages: messages,
            messagesArray: messagesArray,
            messagesOutput: messagesOutput,
            messagesTestInfo: messagesTestInfo,
            contentIsLoading: false,
            currentIndex: 0,
            noCoreference: false,
            prodApi: false,
            url: inputUrl,
            urlAPIOutput: urlAPIOutput,
            selectedTabIndex: 0,
            authenticated: authenticated,
            nlpServer: defaultNlpServer,
            apiServer: defaultApiServer,
            packageNameChoice: false,
            packageName: defaultPackageName,
            userLocation: userFullAddress.split(" ").join("\u00A0")+" | "+userTimezone,
            arrayLocations: arrayLocations,
            keyValueLocations: keyValueLocations,
            listLocationsRadios: listLocationsRadios,
            selectedLocation: defaultSelectedLocation,
            showTestElements: showTestElements,
            typeTestAuto: typeTestAuto,
            offsetMessage: 0,
            noMoreMessagesInDataset: false
        };

        ApiConnector.configureConnection();

        this.handleNLPChange = this.handleNLPChange.bind(this);
        this.handleApiChange = this.handleApiChange.bind(this);
        this.handlePackageNameChange = this.handlePackageNameChange.bind(this);
        this.toggleTestingView = this.toggleTestingView.bind(this);
        this.changeTypeTestAuto = this.changeTypeTestAuto.bind(this);
        this.loadTestsButtonClicked = this.loadTestsButtonClicked.bind(this);
        this.loadOneMessage = this.loadOneMessage.bind(this);
        // this.debug();
    }

    toggleTestingView(e){
        CookiesManager.save('setting-show-test-element', !this.state.showTestElements);
        this.setState({ showTestElements: !this.state.showTestElements, selectedTabIndex: 0 });
    }

    // debug() {
    //     window.onresize = function (event) {
    //         console.log("%c Window size: " + window.innerWidth, 'background: #222; color: #bada55');
    //     }
    // }

    checkAuthentication() {
        let authenticated = false;
        let authString = CookiesManager.load('authenticated');
        if (authString !== "User is authenticated") {
            let password = prompt("Password");
            let auth = Utils.pwdCheck(config.biggerpanTeam, password);
            if (auth) {
                authenticated = true;
                CookiesManager.save('authenticated', "User is authenticated");
            }
        }
        else {
            authenticated = true;
        }

        return authenticated;
    }

    showInfoForMessageAtIndex(index) {
        Utils.dLog("Show info for: " + this.state.messages[index].text);
    }

    openPopUpLocation(locationSelected){

        const jsonLocation = config.userLocations;
        var keyValueLocations = [];
        var arrayLocations = [];
        Object.keys(jsonLocation).forEach(function(key) {
            keyValueLocations[key] = jsonLocation[key];
            arrayLocations.push(jsonLocation[key]);
        });

        console.log('Location : ' + locationSelected.userCity);

        this.setState({ listLocationsRadios: 
            arrayLocations.map((location, index) =>
                <p key={index}><input name="radio-location" type="radio" value={location.userCity ? location.userCity : "Missing Location"} onChange={this.handleLocationChange} checked={locationSelected.userCity === location.userCity ? "checked" : ""}/>{location.userCity ? location.userCity : "Missing Location"}</p>
            )
        });
    }

    loadItemsButtonClicked() {

        let messages = this.state.messages;

        let url = this.state.url;
        let selectedTabIndex = this.state.selectedTabIndex;
        // this.state.selectedTabIndex === 0 -> messages
        // this.state.selectedTabIndex === 1 -> URL
        if (selectedTabIndex === 0) {
            let string = this.state.messages;
            if (typeof string === "string") {
                // If the user typed a URL in the message tab, we switch to the URL tab
                if (Utils.isValidURL(string.replace(/\r?\n|\r/g, "").trim())) {
                    selectedTabIndex = 2;
                    url = string;
                    messages = CookiesManager.load('input-messages');
                }
            }
        }
        else if (selectedTabIndex === 2) {
            let string = this.state.url;
            if (typeof string === "string") {
                // If the user typed a string that's not a URL in the URL tab, we switch to the messages tab
                if (!Utils.isValidURL(string.replace(/\r?\n|\r/g, "").trim())) {
                    selectedTabIndex = 0;
                    messages = string;
                    url = CookiesManager.load('inputUrl');
                }
            }
        }

        // If the user made a mistake, we update the state of the component
        if (this.state.selectedTabIndex !== selectedTabIndex) {
            this.setState({ selectedTabIndex: selectedTabIndex });
        }
        if (this.state.url !== url) {
            this.setState({ url: url });
        }
        if (this.state.messages !== messages) {
            this.setState({ messages: messages });
        }

        console.log('SELETED TAB INDEX : '+selectedTabIndex);

        if (selectedTabIndex === 0) {
            // Messages
            CookiesManager.save('input-messages', messages);
            CookiesManager.save('noCoreference', this.state.noCoreference);
            // CookiesManager.save('prodApi', this.state.prodApi, { path: '/' });
            let messagesArray = messages.split("\n");

            for (let index = 0; index < messagesArray.length; index++) {
                messagesArray[index] = messagesArray[index].trim();
            }

            messages = messagesArray.join("\n");

            this.setState({ messages: messages });

            let cleanedMessagesArray = Utils.cleanStringsArray(messagesArray);
            this.setState({ messagesOutput: cleanedMessagesArray, messagesArray: [], currentIndex: 0 });

            console.log('CLEANED MESSAGE ARRAY LENGTH : '+cleanedMessagesArray.length);

            if (cleanedMessagesArray.length > 0) {
                CookiesManager.save('messages-array', cleanedMessagesArray);
                this.setState({ messagesArray: cleanedMessagesArray, contentIsLoading: true });
                // We add a short timeout here so the this.state has enough time to be updated
                setTimeout(this.loadItemsForMessages.bind(this), 100);
                // this.loadItemsForMessages();
            }
        }
        else {
            // URL
            this.setState({ urlAPIOutput: [] });
            if (typeof url !== "string") {
                alert("Please, enter a URL");
                return;
            }
            CookiesManager.save('inputUrl', url);
            this.setState({ contentIsLoading: true });
            this.loadItemsForURL(url);
        }
    }

    loadItemsForURL(inputUrl) {
        let self = this;
        ApiConnector.callApiForUrl(this.state.prodApi, inputUrl, function (newUrlAPIOutput) {
            self.setState({ urlAPIOutput: [newUrlAPIOutput] });
            CookiesManager.save('url-api-output', newUrlAPIOutput);
            Utils.dLog("Current URL API output: " + JSON.stringify(newUrlAPIOutput, null, 2));
            self.setState({ contentIsLoading: false });
            console.log('content loading to false 0');
        });
    }

    loadItemsForMessages() {

        if (this.state.currentIndex >= this.state.messagesArray.length) {
            return;
        }

        if (!this.state.noCoreference) {
            console.log("Load items with coreference");
            this.loadContentForConversation();
        }
        else {
            console.log('load items without coreference ');
            let message = this.state.messagesArray[this.state.currentIndex];
            this.loadContentForMessage(message);
            let newIndex = this.state.currentIndex + 1;
            this.setState({ currentIndex: newIndex });
            console.log('newIndex : '+newIndex);
            console.log('message Array length : '+this.state.messagesArray.length);

            console.log("MESSAGE ARRAY : " + JSON.stringify(this.state.messagesArray));

            if (newIndex < this.state.messagesArray.length) {
                setTimeout(this.loadItemsForMessages.bind(this), 2000);
            }
        }
    }





    loadContentForMessage(message) {

        let self = this;
        ApiConnector.callApiForMessages(this.state.apiServer, this.state.nlpServer, this.state.packageName, self.state.selectedLocation, this.state.showTestElements, message, 0, false, function (newMessageOutput) {

            
            let currentMessagesOutput = self.state.messagesOutput;
            currentMessagesOutput.push(newMessageOutput);
            self.setState({ messagesOutput: currentMessagesOutput });
            //CookiesManager.save('messages-output', currentMessagesOutput);

            console.log('MESSAGE LENGTH : '+currentMessagesOutput.length);
            console.log('MESSAGE ARRAY LENGTH : ' + self.state.messagesArray.length);
            if (currentMessagesOutput.length >= self.state.messagesArray.length) {
                self.setState({ contentIsLoading: false });
                console.log('content loading to false 1');
            }
        });
    }

    loadContentForConversation() {

        if (!this.state.messagesArray) {
            return;
        }
        if (this.state.messagesArray.length <= 0) {
            return;
        }

        let self = this;
        // Here, we manage the coreference between every message and every messages that are older to this message
        // e.g. if we manage ["I'm at ", "Ok meet you there", "Alright, I'm gonna have an ice cream nearby"]
        // We want to have content for: ["I'm at the Opera"], ["I'm at the Opera", "Ok meet you there"] and ["I'm at the Opera", "Ok meet you there", "Alright, I'm gonna have an ice cream nearby"]
        // We send three requests so we can populate the tiles continuously and we don't need to wait for the whole content to be loaded
        let messagesArrays = [];
        for (let i = 0; i < this.state.messagesArray.length; i++) {

            let actualMessagesArray = [];
            let j = i + 1;
            while (j--) {
                actualMessagesArray.unshift(this.state.messagesArray[j]);
            }

            messagesArrays.push(actualMessagesArray);
            Utils.dLog("Actual Messages Array: " + JSON.stringify(actualMessagesArray, null, 2));
        }

        let index = 0;
        let getContentForMessages = function () {

            if (index >= messagesArrays.length) {
                console.log("END GET CONTENT : " + self.state.messagesTestInfo);
                return;
            }

            console.log("SELECTED LOCATION : " + JSON.stringify(self.state.selectedLocation));

            let messagesArray = messagesArrays[index];
            ApiConnector.callApiForMessages(self.state.apiServer, self.state.nlpServer, self.state.packageName, self.state.selectedLocation, self.state.showTestElements, messagesArray, index, true, function (newMessageOutput) {

                let currentMessagesOutput = self.state.messagesOutput;
                let messageIndex = newMessageOutput.index;
                if (messageIndex < currentMessagesOutput.length) {
                    currentMessagesOutput[messageIndex] = newMessageOutput;
                }
                else {
                    currentMessagesOutput.push(newMessageOutput);
                }
                // currentMessagesOutput.sort(function (a, b) { // We sort the messages output array by index so the messages are displayed in the right order
                //     if (typeof a === "string") {
                //         return false;
                //     }
                //     return (a.index > b.index)
                // });
                self.setState({ messagesOutput: currentMessagesOutput });
                CookiesManager.save('messages-output', currentMessagesOutput);
                //Utils.dLog("Current messages output: " + JSON.stringify(currentMessagesOutput, null, 2));
                let displayedMessagesAreAllWithContent = true;
                currentMessagesOutput.forEach(function (message) {
                    if (typeof message === "string") {
                        displayedMessagesAreAllWithContent = false;
                    }
                });
                if (currentMessagesOutput.length >= messagesArrays.length && displayedMessagesAreAllWithContent) {
                    self.setState({ contentIsLoading: false });
                    console.log('content loading to false 2')
                }
            });

            setTimeout(function () {
                index = index + 1;
                getContentForMessages();
            }, 500);
        }


        getContentForMessages();
        
    }


    changeTypeTestAuto(event){
        const target = event.target;
        
        console.log('Changing type to : '+target.value);

        this.setState({
			typeTestAuto : target.value
        });
        this.setState({noMoreMessagesInDataset: false});
    }


    loadTestsButtonClicked(){

        let self = this;
        self.setState({ contentIsLoading: true });
        self.setState({messagesOutput : []});

        

        // Get the next X messages with the right type from the test server
        let dataToSend = {
            limit: 10,
            type: self.state.typeTestAuto,
            countryCode: self.state.selectedLocation.userCountry
		};


		let qwestOptions = {
			dataType: 'json',
			responseType: 'json',
			crossDomain: true,
			headers: {
				'auth': config.tests.auth,
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin' : '*',
				"cache-control": "no-cache"
			},
			timeout: 60000
		};
		let url = config.tests.url;
        let endpoint = config.tests.getMessagesForAutomaticTestingEdnpoint;
        let messagesToTest;

		qwest.post(url+endpoint, dataToSend, qwestOptions)
			.then(function (xhr, response) {
                console.log('in response');
                
                messagesToTest = response.messages;

                self.setState({offsetMessage : messagesToTest.length});

                // check if we still have results
                if (messagesToTest.length > 0) {
                    for (const [index, element] of messagesToTest.entries()) {
                        setTimeout(function(){
                            self.loadContentForMessage(element);
                            
                        }, index * 1000);
                    }
                    
                } else {
                    self.setState({noMoreMessagesInDataset: true});
                    self.setState({contentIsLoading : false});
                    console.log('content loading to false 3');
                }

            });

    }


    loadOneMessage(validated){
        
        let self = this;


        let dataToSend = {
            limit: 1,
            type: self.state.typeTestAuto,
            offset: 10,
            countryCode: self.state.selectedLocation.userCountry
        };
        
        if (!validated) {
            self.setState({offsetMessage : self.state.offsetMessage + 1});
        }


		let qwestOptions = {
			dataType: 'json',
			responseType: 'json',
			crossDomain: true,
			headers: {
				'auth': config.tests.auth,
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin' : '*',
				"cache-control": "no-cache"
			},
			timeout: 60000
		};
		let url = config.tests.url;
        let endpoint = config.tests.getMessagesForAutomaticTestingEdnpoint;
        let messagesToTest;

		qwest.post(url+endpoint, dataToSend, qwestOptions)
			.then(function (xhr, response) {
                console.log('in response');
                
                messagesToTest = response.messages;

                // check if we still have results
                if (messagesToTest.length > 0) {
                    for (const [index, element] of messagesToTest.entries()) {
                        setTimeout(function(){
                            self.loadContentForMessage(element);
                        }, index * 1000);
                    }
                } else {
                    self.setState({noMoreMessagesInDataset: true});
                    self.setState({contentIsLoading: false});
                    console.log('content loading to false 4');
                }

            });

    }



    toggleCoreferenceCheckboxChange() {
        this.setState({ noCoreference: !this.state.noCoreference });
    }

    toggleProdCheckboxChange() {
        this.setState({ prodApi: !this.state.prodApi });
    }

    willSelectTabAtIndex(index) {
        if (index === 1) {
            this.setState({ showTestElements: true });
        }

        if (index === 0 || index === 1) {
            this.setState({ messagesOutput: [] });
            this.setState({offsetMessage : 0})
        }

        this.setState({ selectedTabIndex: index });
        this.setState({noMoreMessagesInDataset: false});
    }

    handleNLPChange(event) {
        this.setState({ nlpServer: event.target.value });
    }

    handleApiChange(event) {
        this.setState({ apiServer: event.target.value });
    }

    handlePackageNameChange(event) {
        this.setState({ packageNameChoice: event.target.checked });
        if (event.target.checked) {
            this.setState({ packageName: "com.messages.chat" });
        } else {
            this.setState({ packageName: "" });
        }
    }

    handleLocationChange = (changeEvent) => {
        let locationValue = changeEvent.target.value;
        if (!locationValue) {
            locationValue = "Missing Location"
        }
        this.setState({selectedLocation: this.state.keyValueLocations[locationValue]});
        this.setState({userLocation: this.state.keyValueLocations[locationValue].userCoordinates.fullAddress+" | "+this.state.keyValueLocations[locationValue].timezone});
        this.openPopUpLocation(this.state.keyValueLocations[locationValue])
    }

    render() {

        let testElementStyle = {
            position: 'absolute',
            visibility: 'hidden',
            height: 'auto',
            width: 'auto',
            whiteSpace: 'nowrap'
        }

        let loadItemsButton = <div className="loadingItemsContainer">
                <div className="loadingButtonContainer">
                    <button className="loadItemsButton" onClick={() => this.loadItemsButtonClicked()}>Load Items</button>
                </div>
                <div hidden={this.state.selectedTabIndex === 0} className="loaderContainer">
                    <ClipLoader
                        color={'#4CAF50'}
                        loading={this.state.contentIsLoading}
                    />
                </div>
            </div>;

        let loadTestsButton = 
            <div> 
                <div className="loadingButtonContainer">
                    <button className="loadItemsButton" onClick={this.loadTestsButtonClicked}>Load Items</button>
                </div>
                <div className="loaderContainer">
                    <ClipLoader
                        color={'#4CAF50'}
                        loading={this.state.contentIsLoading}
                    />
                </div>
            </div>;
        
        let switchNLPServer = 
            <div className="switchServer switchNLPContainer" hidden={!config.biggerpanTeam}>
                <p>
                    <span>NLP </span> 
                    <select value={this.state.nlpServer} onChange={this.handleNLPChange} className="nlpSelect">
                        <option name="dev" value="dev">Dev</option>
                        <option name="devBis" value="devBis">Dev Bis</option>
                        <option name="prod" value="prod">Prod</option>
                        <option name="prodStandBy" value="prodStandBy">Prod Standby</option>
                    </select>
                </p>
            </div>
        ;

        let switchAPIServer = 
            <div className="switchServer switchAPIContainer" hidden={!config.biggerpanTeam}>
                <p className="paragraph-inline">
                    <span>API </span> 
                    <select value={this.state.apiServer} onChange={this.handleApiChange} className="apiSelect">
                        <option name="dev" value="dev">Dev</option>
                        <option name="prod" value="prod">Prod</option>
                    </select>
                </p>
            </div>
        ;

        let switchApp = 
        <div className="container-app-choice">
            <img src={magicchatlogo} className={"app-log-image"} alt=""/>
            <label>
                <div className="switch container-toggle">
                    <input
                        name="packageNameChoice"
                        type="checkbox"
                        checked={this.state.packageNameChoice}
                        onChange={this.handlePackageNameChange} />
                    <span className={"slider round "+(this.state.packageNameChoice ? "good" : "wrong")}></span>
                </div>
            </label>
            <img src={smartmessageslogo} className={"app-log-image"} alt=""/>
        </div>
            
        ;

        let tileElements = this.state.messagesOutput.map((message, i) => (

            <Message key={i} keyMessage={i} contentIsLoading={this.state.contentIsLoading} showTestElements={this.state.showTestElements} message={message} refresh={typeof message === "string"} selectedTabIndex={this.state.selectedTabIndex} loadOneMessage={this.loadOneMessage} selectedLocation={this.state.selectedLocation} />

            // <div key={i} className={"message-container-"+i}>

            //     <label className={"messageTextLabel " + (typeof message === "string" ? "noResults" : (message.tiles.length > 0 ? "loaded" : "noResults"))}> <button onClick={this.discardMessage(i)} className="btn-danger btn-discard-message" hidden={this.state.selectedTabIndex !== 1}><i className="fas fa-times"></i></button> {typeof message === "string" ? message : message.text}</label>
            //     {/* <Popup trigger={<button className="showInformationButton" hidden={typeof message === "string"} onClick={() => this.showInfoForMessageAtIndex(i)}>
            //     </button>} modal>
            //         <div className="frame">
            //             <div className="scroll">
            //                 <ReactJson className="jsonContainer" name={message.text} src={message.nlpOutput} displayObjectSize={false} displayDataTypes={false} />
            //             </div>
            //         </div>
            //     </Popup> */}


            //     <ShowMessageDetails loading={this.state.contentIsLoading} message={message} name={message.text} src={message.nlpOutput} />

            //     <div hidden={!config.biggerpanTeam || !this.state.showTestElements}>
            //         {typeof message === "string" ? <div></div> : 
            //             <TestValidationForm message={message.text} newMessage={this.state.contentIsLoading} submitName={i} />
            //         }
            //     </div>
                

            //     <div hidden={typeof message === "string"} id="tileGrid">
            //         {typeof message === "string" ? <div></div> : message.tiles.map((tile, j) => (
            //             <div key={j} className="tile-container">
            //                 <Tile tileContent={tile} />
            //             </div>
            //         ))}
            //     </div>
            //     <div hidden={typeof message !== "string" || !this.state.contentIsLoading}>
            //         <div className="messageLoaderContainer">
            //             <ClipLoader
            //                 color={'#4CAF50'}
            //                 loading={true}
            //             />
            //         </div>
            //     </div>
            // </div>
        ));


        let locationIndicator = <Popup onOpen={() => this.openPopUpLocation(this.state.selectedLocation)} trigger={<div className="locationLabelMainContainer" onClick={() => this.showPopUpLocations()}> 
            <img src={pinIndicator} className={"pinImage"} alt=""/>
            <p className="locationLabel">Current location: {this.state.userLocation}</p>
                </div>} modal>

                {close => (
                    <div>
                        <div className="">
                            <div className="scroll">
                                <div className="container-choice-location">
                                    {this.state.listLocationsRadios}
                                </div>
                            </div>
                        </div>
                        <a className="close" onClick={close}>
                        &times;
                        </a>
                    </div>
                )}
            </Popup>
        ;


        let authContent = <div className="App-container">
            <div className="App-header">
                <img className="header-image" src={biggerpanLogo} alt="" />
                <div className="header-title">Predictive A.I. API (v.<span onClick={() => this.toggleTestingView()}>0</span>.1.9)</div>
            </div>
            <div id="informationBoxContainer">
                {locationIndicator}
                
                <StatusCheck selectedServer={this.state.nlpServer} />
            </div>
            <div className="serverChoice" hidden={!config.biggerpanTeam || !this.state.showTestElements}>
                {switchNLPServer}
                {switchAPIServer}
                {switchApp}
            </div>
            <Tabs className="tabs-container" selectedIndex={this.state.selectedTabIndex} onSelect={index => this.willSelectTabAtIndex(index)}>
                <TabList>
                    <Tab>Messages</Tab>
                    <Tab disabled={!config.biggerpanTeam || !this.state.showTestElements} >Messages Auto</Tab>
                    <Tab>URL</Tab>
                    <Tab disabled={!config.biggerpanTeam || !this.state.showTestElements} >Messages List</Tab>
                </TabList>
                <TabPanel>
                    <div className="textAreaContainer">
                        <Textarea ref="messagesTextArea" className="textArea" value={this.state.messages} placeholder="Example: Hey wanna eat sushi?" onChange={e => this.setState({ messages: e.target.value })} />
                        <div hidden={!config.biggerpanTeam}>
                            <input type="checkbox" className="coreference-checkbox" id="coreferenceCheckbox" name="coref" value="Coreference" checked={this.state.noCoreference} onChange={this.toggleCoreferenceCheckboxChange.bind(this)} />
                            <label htmlFor="coreferenceCheckbox">No Coreference</label>

                            {/* <label htmlFor="" className="label-test-elements">
                                <input type="checkbox" className="show-test-elements" name="show-test-elements" checked={this.state.showTestElements} onChange={() => this.toggleTestingView()} />
                                Show test elements
                            </label> */}
                        </div>
                    </div>
                    {loadItemsButton}
                    {tileElements}
                </TabPanel>
                <TabPanel>
                    <div hidden={!config.biggerpanTeam || !this.state.showTestElements} className="message-auto-options textAreaContainer">
                        <label>Choice of test type : </label> <br/>
                        <div>
                            <label><input type="radio" className="radio-message-auto" name="radio-message-auto" onChange={this.changeTypeTestAuto} value="all" checked={this.state.typeTestAuto === "all"} /> All</label>
                            <label><input type="radio" className="radio-message-auto" name="radio-message-auto" onChange={this.changeTypeTestAuto} value="untested" checked={this.state.typeTestAuto === "untested"} /> Untested</label>
                            <label><input type="radio" className="radio-message-auto" name="radio-message-auto" onChange={this.changeTypeTestAuto} value="wrong" checked={this.state.typeTestAuto === "wrong"} /> Wrong</label>
                            <label><input type="radio" className="radio-message-auto" name="radio-message-auto" onChange={this.changeTypeTestAuto} value="pending-review" checked={this.state.typeTestAuto === "pending-review"} /> Pending review</label>
                            <label><input type="radio" className="radio-message-auto" name="radio-message-auto" onChange={this.changeTypeTestAuto} value="good" checked={this.state.typeTestAuto === "good"} /> Good</label>
                        </div>
                    </div>
                    {loadTestsButton}
                    
                    <div hidden={!this.state.noMoreMessagesInDataset}>
                        <h3>No more messages for this dataset</h3>
                    </div>

                    {tileElements}
                    
                </TabPanel>
                <TabPanel>
                    <div className="textAreaContainer">
                        <Textarea ref="urlTextArea" className="textArea urlContainer" data-limit-rows="true" rows="1" value={this.state.url} placeholder="Example: https://en.wikipedia.org/wiki/La_La_Land_(film)" onChange={e => this.setState({ url: e.target.value })} />
                    </div>
                    {loadItemsButton}
                    {this.state.urlAPIOutput.map((object, i) => (
                        <div key={i}>
                            {/*<label className="messageTextLabel">{object.href}</label>*/}
                            <Popup trigger={<button className="showInformationButton" onClick={() => this.showInfoForMessageAtIndex(i)}>
                            </button>} modal>
                                <div className="frame">
                                    <div className="scroll">
                                        <ReactJson className="jsonContainer" name={object.href} src={object.nlpOutput} displayObjectSize={false} displayDataTypes={false} />
                                    </div>
                                </div>
                            </Popup>
                            <div id="tileGrid">
                                {object.tiles.map((tile, j) => (
                                    <div key={j}>
                                        <Tile tileContent={tile} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </TabPanel>
                <TabPanel>
                    <div hidden={!config.biggerpanTeam || !this.state.showTestElements}>
                        <MessageBackOffice selectedLocation={this.state.selectedLocation} />
                    </div>
                </TabPanel>

            </Tabs>
            {/* {loadItemsButton} */}
            {/* <div>
                <input className="old-api-checkbox" type="checkbox" id="prodCheckbox" name="prod" value="Call old version of NLP" checked={this.state.prodApi} onChange={this.toggleProdCheckboxChange.bind(this)} />
                <label htmlFor="prodCheckbox">Call prod</label>
            </div> */}
        </div>;

        let notAuthContent = <div>Not authenticated</div>;
        let rendered = this.state.authenticated ? authContent : notAuthContent;

        return (
            <div>
                {rendered}
                <div style={testElementStyle} id="TestDiv">Hello how are you?</div>
            </div>
        );
    }
}

export default App;