
import React, { Component } from 'react';

import qwest from 'qwest';
import config from './config/config';
import Utils from './Utils';
import Loading from './Loading';

class TestValidationForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			submitName : this.props.submitName,
			message : this.props.message,
			validationNlp: false,
			validationNlpClass: 'untested',
			validationApi: false,
			validationApiClass: 'untested',
			commentApi: '',
			commentNlp: '',
			validationDate: '',
			testingSets: [],
			loading: false,
			loadingDelete: false,
			validated: 'not-validated',
			goalIntents: "",
			infoRetrieved: false,
			iconToDisplay: false,
			newMessage: this.props.newMessage,
			selectedLocation: this.props.selectedLocation,
			outputNlp: this.props.outputNlp,
			outputApi: this.props.outputApi,
			outputApiContentProviders: this.props.outputApiContentProviders,
			missingIntents: false,
			intentParser: this.props.intentParser
		};
  
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.hideLoading = this.hideLoading.bind(this);
		this.handleMessageStatusChange = this.handleMessageStatusChange.bind(this);
		this.handleDeleteMessage = this.handleDeleteMessage.bind(this);
	}
  
	hideLoading() {
		this.setState({loading: false});
		this.setState({loadingDelete: false});
	}


	componentDidMount() {

		let self = this;

		let messageWithoutError = this.state.message.toLowerCase();
		messageWithoutError = messageWithoutError.split("- error")[0].trim();

		let dataToSend = {
			"messages" : [messageWithoutError],
			"countryCode" : self.state.selectedLocation.userCountry
		};

	
		console.log("DATA TO SEND : " + JSON.stringify(dataToSend));

		let qwestOptions = {
			dataType: 'json',
			responseType: 'json',
			crossDomain: true,
			headers: {
				'auth': config.tests.auth,
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin' : '*',
				"cache-control": "no-cache"
			},
			timeout: 60000
		};
		let url = config.tests.url;
		let endpoint = config.tests.getMessagesInfoEndpoint;

		if (!self.infoRetrieved) {
			
			qwest.post(url+endpoint, dataToSend, qwestOptions)
				.then(function (xhr, response) {

					self.setState({infoRetrieved : true}); 

					// we set the state according to the server info
					self.setState({
						commentApi: response.res[self.state.message.toLowerCase()].commentApi
					});

					self.setState({validationApiClass: response.res[self.state.message.toLowerCase()].manualValidationApi});
					switch(response.res[self.state.message.toLowerCase()].manualValidationApi){
						case "untested":
							self.setState({validationApi: false});
							break;
						case "good":
							self.setState({validationApi: true});
							break;
						case "wrong":
							self.setState({validationApi: false});
							break;
						case "pending-review":
							self.setState({validationApi: false});
							break;
						default:

							break;
					}
					

					self.setState({
						commentNlp: response.res[self.state.message.toLowerCase()].commentNlp
					});

					self.setState({
						testingSets: response.res[self.state.message.toLowerCase()].testingSets
					});

					self.setState({validationNlpClass: response.res[self.state.message.toLowerCase()].manualValidationNlp});
					switch(response.res[self.state.message.toLowerCase()].manualValidationNlp){
						case "untested":
							self.setState({validationNlp: false});
							break;
						case "good":
							self.setState({validationNlp: true});
							break;
						case "wrong":
							self.setState({validationNlp: false});
							break;
						case "pending-review":
							self.setState({validationNlp: false});
							break;
						default:

							break;
					}


					self.setState({
						validationDate: response.res[self.state.message.toLowerCase()].validationDate
					});

					self.setState({
						goalIntents: response.res[self.state.message.toLowerCase()].goalIntents
					});

				}).catch(function(err){
					console.log('Test fetching info error : ' + err);
				});

		}
	}

	
	handleSubmit(event) {

		event.preventDefault();
		this.setState({missingIntents: false});
		
		// check if intents is set
		if (this.state.goalIntents === "") {
			console.log("goal intent empty");
			this.setState({missingIntents: true});
			return null;
		}

		// if (this.state.intentParser === "Probabilistic Intent Parser") {
		// 	alert('The message is from the Probabilistic. You can\'t save it to the database')
		// 	return null;
		// }

		
		this.setState({validated: 'not-validated'});
		this.props.hasBeenValidated();

		let url = config.tests.url;
		// let url = config.tests.localUrl;
		let endpoint = config.tests.validateMessageEndpoint;

		// default options to connect
		let qwestOptions = {
            dataType: 'json',
			responseType: 'json',
			crossDomain: true,
            headers: {
                'auth': config.tests.auth,
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin' : '*',
				"cache-control": "no-cache"
            },
			timeout: 60000
		};

		
		let messageWithoutError = this.state.message.toLowerCase();
		messageWithoutError = messageWithoutError.split("- error")[0];

		console.log(this.state.outputApiContentProviders)

		let dataToSend = {
			message : messageWithoutError,
			validationNlp : this.state.validationNlpClass,
			validationApi : this.state.validationApiClass,
			commentNlp : this.state.commentNlp,
			commentApi : this.state.commentApi,
			goalIntents : this.state.goalIntents,
			countryCode: this.state.selectedLocation.userCountry,
			outputNlp: this.state.outputNlp,
			outputApi: this.state.outputApi,
			outputApiContentProviders: this.state.outputApiContentProviders,
			testingSets: this.state.testingSets
		}

		this.setState({loading: true});

		var self = this;

		qwest.post(url+endpoint, dataToSend, qwestOptions)
            .then(function (xhr, response) {
				Utils.dLog("Response: " + JSON.stringify(response, null, 2));
				self.setState({validated: 'validated'});
				self.setState({iconToDisplay: 'fa-check'});
            })
            .catch(function (e, xhr, response) {
				console.log('error : ' + e);
            }).complete(function(){
				self.hideLoading();
			});
	}

	

	handleInputChange(event) {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

	
		this.setState({
			[name]: value
		});

	}

	handleMessageStatusChange(event){
		const target = event.target;
		const value = target.checked;
		const name = target.name;


		if (value) {
			this.setState({[name+"Class"]: "good"});
		} else {
			this.setState({[name+"Class"]: "wrong"});
		}
	
		this.setState({
			[name]: value
		});
	}


	handleDeleteMessage(event){
		event.preventDefault();


		let url = config.tests.url;
		let endpoint = config.tests.deleteMessageEndpoint;

		// default options to connect
		let qwestOptions = {
            dataType: 'json',
			responseType: 'json',
			crossDomain: true,
            headers: {
                'auth': config.tests.auth,
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin' : '*',
				"cache-control": "no-cache"
            },
			timeout: 60000
		};

		

		let dataToSend = {
			message : this.state.message.toLowerCase(),
		}

		this.setState({loadingDelete: true});

		var self = this;

		qwest.delete(url+endpoint, dataToSend, qwestOptions)
            .then(function (xhr, response) {
				Utils.dLog("Response: " + JSON.stringify(response, null, 2));
				
            })
            .catch(function (e, xhr, response) {
				console.log('error : ' + e);
            }).complete(function(){
				self.hideLoading();
			});
	}

	// componentWillReceiveProps(props) {

	// 	const refresh = this.props;
	// 	if (props.refresh !== refresh) {
	// 		this.setState({newMessage: this.props.newMessage});
	// 	}
	// }

	static getDerivedStateFromProps(props, state) {
		if (props.newMessage !== state.newMessage) {
			return {
				newMessage: props.newMessage,
			};
		}

		if (props.selectedLocation !== state.selectedLocation) {
			return {
				selectedLocation: props.selectedLocation,
			};
		}

		if (props.outputNlp !== state.outputNlp) {
			return {
				outputNlp: props.outputNlp,
			};
		}

		if (props.outputApi !== state.outputApi) {
			return {
				outputApi: props.outputApi,
			};
		}

		if (props.outputApiContentProviders !== state.outputApiContentProviders) {
			return {
				outputApiContentProviders: props.outputApiContentProviders,
			};
		}

		if (props.intentParser !== state.intentParser) {
			return {
				intentParser: props.intentParser,
			};
		}

		return null;
	}
  
	render() {

		if(!this.state.newMessage) {
			
			return (

				<form className="test-validation-form" onSubmit={this.handleSubmit}>
					<div className="form-inline">

						<label>
							Goal intents : 
							<select name="goalIntents" value={this.state.goalIntents} onChange={this.handleInputChange}>
							<option value="">...</option>
							<option value="find_location,misc,book_restaurant">find_location, misc, book_restaurant</option>
							<option value="find_location,misc,buy">find_location, misc, buy</option>
							<option value="find_location,misc,find_eating_drinking_venue">find_location, misc, find_eating_drinking_venue</option>
							<option value="find_location,misc,misc">find_location, misc, misc</option>
							<option value="find_location,practice_activity,misc">find_location, practice_activity, misc</option>
							<option value="find_location,search_restaurant,misc">find_location, search_restaurant, misc</option>
							<option value="find_location,travel,misc">find_location, travel, misc</option>
							<option value="find_location,watch_movie,misc">find_location, watch_movie, misc</option>
							<option value="find_professional,borrow_money,buy">find_professional, borrow_money, buy</option>
							<option value="find_professional,borrow_money,misc">find_professional, borrow_money, misc</option>
							<option value="find_professional,misc,buy">find_professional, misc, buy</option>
							<option value="find_professional,misc,misc">find_professional, misc, misc</option>
							<option value="find_professional,travel,misc">find_professional, travel, misc</option>
							<option value="meeting,borrow_money,buy">meeting, borrow_money, buy</option>
							<option value="meeting,borrow_money,misc">meeting, borrow_money, misc</option>
							<option value="meeting,misc,attend_event">meeting, misc, attend_event</option>
							<option value="meeting,misc,book_restaurant">meeting, misc, book_restaurant</option>
							<option value="meeting,misc,buy">meeting, misc, buy</option>
							<option value="meeting,misc,find_eating_drinking_venue">meeting, misc, find_eating_drinking_venue</option>
							<option value="meeting,misc,misc">meeting, misc, misc</option>
							<option value="meeting,misc,request_location">meeting, misc, request_location</option>
							<option value="meeting,practice_activity,misc">meeting, practice_activity, misc</option>
							<option value="meeting,search_restaurant,book_restaurant">meeting, search_restaurant, book_restaurant</option>
							<option value="meeting,search_restaurant,buy">meeting, search_restaurant, buy</option>
							<option value="meeting,search_restaurant,misc">meeting, search_restaurant, misc</option>
							<option value="meeting,send_location,misc">meeting, send_location, misc</option>
							<option value="meeting,travel,attend_event">meeting, travel, attend_event</option>
							<option value="meeting,travel,book_restaurant">meeting, travel, book_restaurant</option>
							<option value="meeting,travel,buy">meeting, travel, buy</option>
							<option value="meeting,travel,find_eating_drinking_venue">meeting, travel, find_eating_drinking_venue</option>
							<option value="meeting,travel,misc">meeting, travel, misc</option>
							<option value="meeting,watch_movie,misc">meeting, watch_movie, misc</option>
							<option value="meeting,watch_movie,buy">meeting, watch_movie, buy</option>
							<option value="misc,borrow_money,buy">misc, borrow_money, buy</option>
							<option value="misc,borrow_request_money,buy">misc, borrow_request_money, buy</option>
							<option value="misc,borrow_request_money,misc">misc, borrow_request_money, misc</option>
							<option value="misc,borrow_money,misc">misc, borrow_money, misc</option>
							<option value="misc,misc,attend_event">misc, misc, attend_event</option>
							<option value="misc,misc,book_restaurant">misc, misc, book_restaurant</option>
							<option value="misc,misc,buy">misc, misc, buy</option>
							<option value="misc,misc,find_eating_drinking_venue">misc, misc, find_eating_drinking_venue</option>
							<option value="misc,misc,misc">misc, misc, misc</option>
							<option value="misc,misc,request_money">misc, misc, request_money</option>
							<option value="misc,practice_activity,misc">misc, practice_activity, misc</option>
							<option value="misc,search_restaurant,buy">misc, search_restaurant, buy</option>
							<option value="misc,search_restaurant,find_eating_drinking_venue">misc, search_restaurant, find_eating_drinking_venue</option>
							<option value="misc,search_restaurant,misc">misc, search_restaurant, misc</option>
							<option value="misc,send_location,misc">misc, send_location, misc</option>
							<option value="misc,send_money,buy">misc, send_money, buy</option>
							<option value="misc,send_money,misc">misc, send_money, misc</option>
							<option value="misc,send_money,request_money">misc, send_money, request_money</option>
							<option value="misc,travel,attend_event">misc, travel, attend_event</option>
							<option value="misc,travel,book_restaurant">misc, travel, book_restaurant</option>
							<option value="misc,travel,buy">misc, travel, buy</option>
							<option value="misc,travel,find_eating_drinking_venue">misc, travel, find_eating_drinking_venue</option>
							<option value="misc,travel,misc">misc, travel, misc</option>
							<option value="misc,watch_movie,buy">misc, watch_movie, buy</option>
							<option value="misc,watch_movie,misc">misc, watch_movie, misc</option>
							<option value="order_food,misc,attend_event">order_food, misc, attend_event</option>
							<option value="order_food,misc,buy">order_food, misc, buy</option>
							<option value="order_food,misc,misc">order_food, misc, misc</option>
							<option value="order_food,misc,request_money">order_food, misc, request_money</option>
							<option value="order_food,search_restaurant,buy">order_food, search_restaurant, buy</option>
							<option value="order_food,search_restaurant,misc">order_food, search_restaurant, misc</option>
							</select>
						</label>

						<div hidden={!this.state.missingIntents}>
							<label className="red">Goal intents are missing</label>
						</div>

						<div hidden={!this.state.validationDate} className="label-date">
							<label>Validation date : {this.state.validationDate}</label>
						</div>

						<button hidden={!this.state.goalIntents} className={"delete-button loader-button"} disabled={this.state.loadingDelete} onClick={this.handleDeleteMessage}>
							Delete message
							<Loading loading={this.state.loadingDelete} iconToDisplay={this.state.iconToDisplay} />
						</button>
					</div>
					<div className="form-inline">
						<label>
							Validation Nlp :
							<div className="switch container-toggle">
								<input
									name="validationNlp"
									type="checkbox"
									checked={this.state.validationNlp}
									onChange={this.handleMessageStatusChange} />
								<span className={"slider round "+this.state.validationNlpClass}></span>
							</div>
							
						</label>
						<label>
							<input type="text" className="text-input" name="commentNlp" value={this.state.commentNlp} onChange={this.handleInputChange} placeholder="Comment" />
						</label>
					</div>
					
		
					<div className="form-inline">
						<label>
							Validation Api :
							<div className="switch container-toggle">
								<input
									name="validationApi"
									type="checkbox"
									checked={this.state.validationApi}
									onChange={this.handleMessageStatusChange} />
								<span className={"slider round "+this.state.validationApiClass}></span>
							</div>
						</label>
					
						<label>
							<input type="text" className="text-input" name="commentApi" value={this.state.commentApi} onChange={this.handleInputChange} placeholder="Comment" />
						</label>
						
						<button type="submit" className={this.state.validated + " loader-button"} disabled={this.state.loading}>
							Validate 
							<Loading loading={this.state.loading} iconToDisplay={this.state.iconToDisplay} />
						</button>
					</div>

				</form>

			);
		} else {
			return (
				<span></span>
			);
		}
	}
}

export default TestValidationForm;